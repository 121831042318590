import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function updateAttendanceClient(
    officialId,
    checkedin,
    userid
) {
    console.log(
        '\n\n\n\nACTION - update attendance client action has been called'
    );
    const url = getApiUrl('/studio-admin/update-attendance');
    const results = await axios.post(url, {
        officialId,
        checkedin,
        userid,
    });
    console.log('results after update attendance are', results);
    const { data } = results;
    return data;
}
