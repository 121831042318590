import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
// import AutoCompleteSearch from 'components/search/AutoCompleteClientSearchTiny';
import AutoCompleteSearch from 'components/search/AutoCompleteClientSearchTinyNew';
import LocalizationSection from './LocalizationSection';

import { useSearch } from 'routes/searchContext';

// assets
import { IconMenu2 } from '@tabler/icons-react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const { dibsId } = useParams();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { showSearch } = useSearch();
    const [showLocalization, setShowLocalization] = useState(false);
    useEffect(() => {
        if (dibsId === '226' || dibsId === '260') {
            setShowLocalization(true);
        } else {
            setShowLocalization(false);
        }
    }, [dibsId]);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                id="top-header-section-dibs"
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <Box
                    component="span"
                    id="top-logo-section-dibs"
                    sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
                >
                    <LogoSection />
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.palette.dark.main
                                : theme.palette.secondary.light,
                        color:
                            theme.palette.mode === 'dark'
                                ? theme.palette.secondary.main
                                : theme.palette.secondary.dark,
                        '&:hover': {
                            background:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.secondary.main
                                    : theme.palette.secondary.dark,
                            color:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.secondary.light
                                    : theme.palette.secondary.light,
                        },
                    }}
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    color="inherit"
                >
                    <IconMenu2 stroke={1.5} size="1.3rem" />
                </Avatar>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            {showSearch && (
                <Box
                    id="top-search"
                    sx={{ ml: 2 }}
                    alignItems="center"
                    border={0}
                    // hidden={!showSearchBarAtTop}
                >
                    <AutoCompleteSearch idtouse="top-tool-client-search" />
                </Box>
            )}

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {showLocalization && (
                <Box
                    sx={{ display: { xs: 'none', sm: 'block' } }}
                    id="localization-section"
                >
                    <LocalizationSection />
                </Box>
            )}

            {/* notification & profile */}
            <NotificationSection />
            <ProfileSection />

            {/* mobile header */}
            <Box
                sx={{ display: { xs: 'block', sm: 'none' } }}
                id="box-mobile-header"
            >
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
