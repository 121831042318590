import axios from 'axios';

export default async function checkSubscriptionStatus({
    dibsId,
    stripeidAdmin,
}) {
    if (dibsId === 0) {
        return null;
    }
    try {
        const baseURL = process.env.REACT_APP_BASE_URL;
        const basicurl = `${baseURL}/stripe/check-status-stripe-subscription`;

        const reqbody = {
            dibsId,
            stripeidAdmin,
        };
        if (!stripeidAdmin) {
            return null;
        }
        const response = await axios.post(basicurl, reqbody);
        const { data } = response;

        return data;
    } catch (err) {
        console.log(
            `error from checking stripe subscription on stripe admin: ${err}`
        );
        return err;
    }
}
