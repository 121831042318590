import { dispatch } from 'store';

import {
    setInstructorAltName,
    setStudioName,
    setCustomerServicePhone,
    setTwilioCustomNumUS,
    setShowApptsOnWidget,
    setShowApptsOnAdmin,
    setCustomerServiceEmail,
    setStudioImageUrls,
    setPaymentContactInfo,
    setTerms,
    setAdminStripeId,
    setAdminAccountStripeId,
    setAdminAllStripeIds,
} from 'store/slices/dibsstudio';
import createNewCustomerIdStripe from './stripe/admin/createNewCustomerId';
import checkSubscriptionStatus from './stripe/admin/checkSubscriptionStatus';
import setFullSubscriptionInfo from './stripe/admin/setFullSubscriptionDataInStore';
const environ = process.env.NODE_ENV;

export default async function setUserInStore(studiodata, dibsId) {
    let stripeIdToCheck = null;
    const {
        customerServicePhone,
        twilioCustomNumUS,
        showAppts,
        heroUrl,
        colorLogo,
        terms,
        billingContact,
        billingEmail,
        stripeId,
        stripeAccountId,
        stripeIdTest,
        stripeIdProd,
        locationAddress,
        locationAddress2,
        locationCity,
        locationState,
        locationZipcode,
        country,
    } = studiodata;
    dispatch(setInstructorAltName(studiodata.instructorAltName));
    dispatch(setStudioName(studiodata.studioName));
    dispatch(setCustomerServicePhone(customerServicePhone));
    if (twilioCustomNumUS) dispatch(setTwilioCustomNumUS(twilioCustomNumUS));
    dispatch(setCustomerServiceEmail(studiodata.customerServiceEmail));
    dispatch(setStudioImageUrls({ heroUrl, colorLogo }));
    dispatch(setTerms(terms));
    dispatch(
        setPaymentContactInfo({
            billing_contact: billingContact,
            billing_email: billingEmail,
        })
    );
    dispatch(setShowApptsOnWidget(showAppts));
    dispatch(setShowApptsOnAdmin(showAppts));
    console.log('\n\n\nSTripe id is', stripeId);
    console.log('\n\n\nStripe account id is', stripeAccountId);
    dispatch(setAdminStripeId(stripeId));
    dispatch(setAdminAccountStripeId(stripeAccountId));
    dispatch(
        setAdminAllStripeIds({
            stripeid: stripeId || '',
            stripeid_test: stripeIdTest || '',
            stripeid_prod: stripeIdProd || '',
        })
    );
    let neednewstripeid = false;
    if (environ === 'development') {
        console.log('\n\n\nThis is a development environment');
        if (!stripeIdTest) {
            console.log('create a new stripe id for this studio');
            neednewstripeid = true;
        } else {
            stripeIdToCheck = stripeIdTest;
            dispatch(
                setAdminAllStripeIds({
                    stripeid: stripeIdTest,
                    stripeid_test: stripeIdTest,
                    stripeid_prod: '',
                })
            );
        }
    } else {
        if (!stripeId) {
            console.log('create a new stripe id for this studio');
            neednewstripeid = true;
        } else {
            stripeIdToCheck = stripeId;
            dispatch(
                setAdminAllStripeIds({
                    stripeid: stripeId,
                    stripeid_test: '',
                    stripeid_prod: stripeId,
                })
            );
        }
    }

    if (neednewstripeid) {
        const newCustomer = await createNewCustomerIdStripe({
            dibsId,
            billingEmail,
            billingContact,
            businessName: studiodata.studioName,
            locationAddress,
            locationAddress2,
            locationCity,
            locationState,
            locationZipcode,
            country,
        });
        const { stripeIdCreated } = newCustomer;
        stripeIdToCheck = stripeIdCreated;
        if (environ === 'development') {
            dispatch(
                setAdminAllStripeIds({
                    stripeid: stripeIdCreated,
                    stripeid_test: stripeIdCreated,
                    stripeid_prod: '',
                })
            );
        } else {
            dispatch(
                setAdminAllStripeIds({
                    stripeid: stripeIdCreated,
                    stripeid_test: '',
                    stripeid_prod: stripeIdCreated,
                })
            );
        }
    }
    const subscriptionStatus = await checkSubscriptionStatus({
        dibsId,
        stripeidAdmin: stripeIdToCheck,
    });
    console.log('\n\n\nSUbscription Status is\n', subscriptionStatus);
    if (subscriptionStatus) {
        const { success } = subscriptionStatus;
        if (success) {
            const { canceledSubscriptions, subscriptions } = subscriptionStatus;
            if (subscriptions?.length > 0) {
                console.log(
                    '\n\n\nHAS ACTIVE subscription ---> so let us update in store'
                );
                setFullSubscriptionInfo(subscriptions[0], 'active');
            } else if (canceledSubscriptions?.length > 0) {
                console.log(
                    '\n\n\nNO ACTIVE sub but there is a CANCELED subscription ---> so let us update in store'
                );
                setFullSubscriptionInfo(canceledSubscriptions[0], 'canceled');
            } else {
                console.log(
                    '\n\n\nTHERE IS NO SUBSCRIPTION EVER ---> so let us update in store'
                );
                setFullSubscriptionInfo(null, 'never');
            }
        }
    } else {
        console.log(
            'No subscription found - there was an error getting the subscription - bc it should always return something even if empty'
        );
    }

    return 1;
}
