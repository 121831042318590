import axios from 'axios';
import { getApiUrl } from 'helpers';

export const updateStudioAdminConfigs = async (dibsId) => {
    console.log('\n\n\ndibs id is', dibsId);
    const url = getApiUrl('/admin/get-more-configs');
    let data;
    await axios
        .post(url, { dibsStudioId: dibsId })
        .then((response) => {
            console.log('\n\n\nresponse from the api call is', response);
            data = response.data;
        })
        .catch((error) => {
            console.log('update basic configs new error is', error);
        });
    return data;
};
