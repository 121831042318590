import PropTypes from 'prop-types';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import ErrorText from 'components/errors/errorText';
import SuccessText from 'components/errors/successText';

import { setDibsStudioId } from 'store/slices/dibsstudio';
import checkPermissions from 'actions/studio-admin-users/checkPermissionsThisStudio';

// project imports

import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// import Google from 'assets/images/icons/social-google.svg';
import { useDispatch } from 'store';
import { setThisUserDibsStudioId } from 'store/slices/studioemployeeuser';
import { sendPasswordReset } from 'firebaseConfig';

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ loginProp, ...others }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    let { dibsId } = useParams();

    const [errorText, setErrorText] = React.useState('');
    const [successText, setSuccessText] = React.useState('');
    const [hasError, setHasError] = React.useState(false);
    const [hasSuccess, setHasSuccess] = React.useState(false);
    const [buttonText, setButtonText] = React.useState('Reset your password');
    const [didSubmit, setDidSubmit] = React.useState(false);

    React.useEffect(() => {
        dispatch(setDibsStudioId(dibsId));
        dispatch(setThisUserDibsStudioId(dibsId));
    }, [dibsId, dispatch]);

    const myStyle = {
        minWidth: '400px',
    };
    const resetPassword = async (email) => {
        await sendPasswordReset(email);
    };

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{ m: 0, p: 0 }}
            >
                <Grid
                    border={0}
                    container
                    alignItems="center"
                    justifyContent="center"
                    id="login-container-form-forgot"
                >
                    <Formik
                        initialValues={{
                            // email: 'youremail@yourstudio.com',
                            email: '',
                            // password: '123456789',
                            submit: null,
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email('Must be a valid email')
                                .max(255)
                                .required('Email is required'),
                        })}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                if (buttonText === 'Return to login') {
                                    navigate(`/login/${dibsId}`);
                                } else {
                                    await checkPermissions(
                                        values.email,
                                        dibsId
                                    ).then(async (res) => {
                                        const { employeeId } = res;
                                        if (employeeId === 0) {
                                            setErrorText(
                                                `It looks like you don't have permission to login to this admin panel. Please confirm your email address.`
                                            );
                                            setHasError(true);
                                            setTimeout(() => {
                                                setHasError(false);
                                                setErrorText('');
                                            }, 5000);
                                        } else {
                                            setHasError(false);
                                            setErrorText('');
                                            // send the user a password reset email
                                            resetPassword(values.email);
                                            // display the confirmation that the email was sent
                                            setHasSuccess(true);
                                            setSuccessText(
                                                'A password reset email has been sent to your email address.'
                                            );
                                            setButtonText('Return to login');
                                            setDidSubmit(true);
                                            setTimeout(() => {
                                                setHasSuccess(false);
                                                setSuccessText('');
                                            }, 10000);
                                        }
                                    });
                                }
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form
                                style={myStyle}
                                noValidate
                                onSubmit={handleSubmit}
                                {...others}
                            >
                                {hasError && <ErrorText error={errorText} />}
                                {hasSuccess && (
                                    <SuccessText success={successText} />
                                )}
                                <FormControl
                                    fullWidth
                                    error={Boolean(
                                        touched.email && errors.email
                                    )}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-email-login">
                                        Email Address / Username
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="Email Address / Username"
                                        inputProps={{}}
                                        autoComplete="email"
                                        disabled={didSubmit}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText
                                            error
                                            id="standard-weight-helper-text-email-login"
                                        >
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}
                                <Box sx={{ mt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            sx={{
                                                borderColor: `${theme.palette.primary[800]} !important`,
                                                backgroundColor: `${theme.palette.secondary[400]} !important`,
                                            }}
                                        >
                                            {buttonText}
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};

FirebaseLogin.propTypes = {
    loginProp: PropTypes.number,
};

export default FirebaseLogin;
