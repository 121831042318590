import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const validatePhoneNumberIntl = (phoneNumber) => {
    try {
        // Parse the phone number without specifying a region
        const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
        // Check if the number is possible and valid
        const isValid =
            phoneUtil.isPossibleNumber(parsedNumber) &&
            phoneUtil.isValidNumber(parsedNumber);

        return { isValidIntl: isValid, parsedNumberIntl: parsedNumber };
    } catch (error) {
        // If an error occurs during parsing, the number is invalid
        return { isValid: false };
    }
};

export const formatPhoneNumberIntl = (parsedNumber) => {
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
};

export const getCountryCode = (parsedNumber) => {
    return phoneUtil.getRegionCodeForNumber(parsedNumber);
};
export const formatPhoneNumberDomestic = (parsedNumber) => {
    const number = phoneUtil.parse(parsedNumber, 'US');
    return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
};
