import { useEffect, useState } from 'react';
import React from 'react';
import {
    Grid,
    Divider,
    Button,
    Switch,
    FormGroup,
    FormControlLabel,
    Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { capitalizeString } from 'helpers';

import AutocompleteField from 'components/search/AutoCompleteClientSearchShort';
import AutocompleteFieldUI from 'ui-component/textfields/Autocomplete';
import DatePicker from 'ui-component/pickers/DatePicker';
import TimePicker from 'ui-component/pickers/TimePicker';
import BookedClients from 'components/schedule-sections/bookedClients';
import BookedClientsPotentially from 'components/schedule-sections/potentiallyAssignedClients';
import NewClientAccount from 'components/clients/createClient';

import createNewAppt from 'actions/appointments/createNewAppt';
import updateExistingEvent from 'actions/schedule/updateExistingEvent';
import addUserToAppt from 'actions/appointments/addUserToAppt';
import setAlert from 'actions/status/setAlert';
import sendConfirmationEmail from 'actions/emails/send-confirmation-appt';
import sendSmsConfirmation from 'actions/emails/send-sms-confirmation-appt';
import sendOpsEmail from 'actions/comm-logs/sendStandAloneOpsEmailNew';
import confirmInstructorIsAvailable from 'actions/instructors/confirmAvailability';
import getAttendees from 'actions/schedule/getAttendees';
import { getEvents } from 'store/slices/calendar';
import { setApptsBeingConfirmed } from 'store/slices/cart';
import AddAnotherItem from 'ui-component/buttons/AddAnotherItem';
import SummaryApptBeingCreated from 'ui-component/displays/Summary';

import { useSelector, dispatch } from 'store';

import { capitalizeFirstLetter } from 'helpers/general';
import {
    multiApptsTest,
    newappttest,
    apptToPassTest,
    multiMultiTesting,
} from 'constants/testing/appt-examples';

dayjs.extend(utc);

const isTestingCode = false;
const sendSMSForTesting = true;

function compareByName(a, b) {
    return a.label.localeCompare(b.label);
}
const ApptFields = ({
    onCancel,
    initialStartDate,
    initialStartTime,
    initialEndTime,
    selectedEvent,
    actionTaking,
    setUpdateAllEvents,
    isBlockingTime,
    refreshCalendar,
    setAttendeesAffected,
    setShowAlertWithEmailSms,
    setEventIdEditing,
}) => {
    const theme = useTheme();
    const { apptTypes } = useSelector((state) => state.appointments);
    const { instructorData } = useSelector((state) => state.instructors);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);

    const { instructorAltName, instructorPref } = useSelector(
        (state) => state.dibsstudio.studioConfig
    );
    const [showErrorGuidance, setShowErrorGuidance] = useState(false);
    const [errorMsgToShow, setErrorMsgToShow] = useState('');
    const [instructorTitle, setInstructorTitle] = useState('Name');

    useEffect(() => {
        if (instructorAltName) {
            setInstructorTitle(`${capitalizeString(instructorAltName)} Name`);
        } else {
            setInstructorTitle('Name');
        }
    }, [instructorAltName]);
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );
    const { timeZone, studioName } = useSelector(
        (state) => state.dibsstudio.config
    );
    const { customerServicePhone, twilioCustomNumUs } = useSelector(
        (state) => state.dibsstudio.customerService
    );
    const [selectionOptions, setSelectionOptions] = useState([]);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const [apptTypeSelected, setApptTypeSelected] = useState(null);
    const [instructorSelected, setInstructorSelected] = useState(null);
    const [endTimeToPass, setEndTimeToPass] = useState(initialEndTime);
    const [timeOfFirstApptInCaseOfMulti, setTimeOfFirstApptInCaseOfMulti] =
        useState(null);

    const [multiAppts, setMultiAppts] = useState([]);
    const [buttonText, setButtonText] = useState('Create Appointment');
    const [cancelText, setCancelText] = useState('Cancel');
    const [isAddingNewClient, setIsAddingNewClient] = useState(false);
    const [hasInitialClientsInAppt, setHasInitialClientsInAppt] =
        useState(false);
    const [clientsNeedAddtlNotifPrompt, setClientsNeedAddtlNotifPrompt] =
        useState(false);

    const [showAutoCompleteClient, setShowAutoCompleteClient] = useState(true);

    const [startTimeToPass, setStartTimeToPass] = useState(initialStartTime);
    const [minutesToAdd, setMinutesToAdd] = useState(30);
    const [manuallySetEnd, setManuallySetEnd] = useState(false);
    const [clientSelected, setClientSelected] = useState(null);
    const [sendEmailConfirmation, setSendEmailConfirmation] = useState(true);
    const [sendSMSConfirmation, setSendSMSConfirmation] = useState(true);
    const [smsLabel, setSMSLabel] = useState('Send SMS/Text Confirmation');
    const [emailLabel, setEmailLabel] = useState('Send Email Confirmation');
    const [attendeeForThisEvent, setAttendeeForThisEvent] = useState([]);
    const [hideSaveButtons, setHideSaveButtons] = useState(false);
    const [eventidSelected, setEventidSelected] = useState(null);
    const [showBookedClients, setShowBookedClients] = useState(false);
    const [updateEvents, setUpdateEvents] = useState(false);
    const [itemsChanged, setItemsChanged] = useState([]);
    const [dateToPass, setDateToPass] = useState(initialStartDate);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [addingAnotherAppt, setAddingAnotherAppt] = useState(false);
    const [atleastOneApptHasBeenSelected, setAtleastOneApptHasBeenSelected] =
        useState(false);

    useEffect(() => {
        return () => {
            setIsSubmitting(false);
            setMultiAppts([]);
            setAtleastOneApptHasBeenSelected(false);
        };
    }, []);

    useEffect(() => {
        if (!multiAppts) {
            console.log('there are no multi appts');
        }
        if (multiAppts?.length === 0) {
            setAddingAnotherAppt(false);
            setTimeOfFirstApptInCaseOfMulti(startTimeToPass || null);
        } else {
            setTimeOfFirstApptInCaseOfMulti(multiAppts[0].startTimeToPass);
        }
    }, [multiAppts, startTimeToPass]);
    useEffect(() => {
        if (isBlockingTime) {
            setButtonText('Save Blocked Time');
            setManuallySetEnd(true);
        }
    }, [isBlockingTime]);
    useEffect(() => {
        if (!isBlockingTime) {
            if (actionTaking === 'editing') {
                setButtonText('Update Appointment');
                setCancelText('Do Not Update');
            } else {
                setButtonText('Create Appointment');
                setCancelText('Cancel');
            }
        }
    }, [actionTaking, isBlockingTime]);

    useEffect(() => {
        const getAttendeeInfo = async () => {
            await getAttendees(selectedEvent.eventid)
                .then((res) => {
                    const { attendees } = res;
                    if (attendees) {
                        if (attendees?.length > 0) {
                            setAttendeeForThisEvent(attendees);
                            setShowBookedClients(true);
                            setHasInitialClientsInAppt(true);
                        } else {
                            setAttendeeForThisEvent([]);
                            setShowBookedClients(false);
                            setHasInitialClientsInAppt(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log('error receievd was', error);
                });
        };
        if (selectedEvent) {
            setApptTypeSelected({
                label: selectedEvent.apptTitle,
                apptdefaultprice: selectedEvent.price_dibs,
                eventid: selectedEvent.eventid,
            });
            const { instructor } = selectedEvent;
            if (instructor) {
                const lname = instructor.lastname;
                let fullname;
                if (lname) {
                    if (lname?.length > 1) {
                        fullname = `${capitalizeFirstLetter(instructor.firstname)} ${lname}`;
                    }
                } else {
                    fullname = instructor.firstname;
                }
                const newinfo = {
                    value: instructor.id,
                    label: fullname,
                };
                setInstructorSelected(newinfo);
            }

            getAttendeeInfo();
            setEventidSelected(selectedEvent.eventid);
        }
        if (updateEvents) {
            getAttendeeInfo();
            setUpdateEvents(false);
        }
    }, [selectedEvent, updateEvents]);

    useEffect(() => {
        if (apptTypes?.length > 0) {
            const options = apptTypes.map((appt) => {
                return {
                    label: appt.appointment_type,
                    value: appt.id,
                    minutes: appt?.length_minutes,
                    apptdefaultprice: appt.default_price,
                };
            });
            const optionsSorted = options.sort(compareByName);
            setSelectionOptions(optionsSorted);
        }
    }, [apptTypes]);
    useEffect(() => {
        if (instructorData?.length > 0) {
            const filteredForEnabled = instructorData.filter((i) => i.enabled);
            const options = filteredForEnabled.map((i) => {
                const lname = i.lastname;
                let fullname;
                let firstname;
                let lastname;
                if (lname?.length > 1) {
                    const first = capitalizeString(i.firstname);
                    const last = capitalizeString(lname);
                    fullname = `${first} ${last}`;
                    firstname = first;
                    lastname = last;
                } else {
                    fullname = capitalizeString(i.firstname);
                }
                return {
                    label: fullname,
                    value: i.id,
                    email: i.email,
                    phone: i.mobilephone,
                    associated_color: i.associated_color,
                    imgUrl: i.image_url,
                    firstname: firstname,
                    lastname: lastname,
                };
            });
            const optionsSorted = options.sort(compareByName);
            setInstructorOptions(optionsSorted);
        }
    }, [instructorData]);
    useEffect(() => {
        if (!manuallySetEnd && !selectedEvent) {
            const baseForEndTime = dayjs.utc(startTimeToPass);
            const newEndTime = baseForEndTime.add(minutesToAdd, 'minutes');

            setEndTimeToPass(newEndTime);
        }
    }, [
        apptTypeSelected,
        manuallySetEnd,
        minutesToAdd,
        addingAnotherAppt,
        selectedEvent,
        startTimeToPass,
    ]);
    const removeApptFromMulti = (fullAppt) => {
        const appttoremove = fullAppt.apptTypeSelected.value;

        const newAppts = multiAppts.filter((appt, i) => {
            return appt.apptTypeSelected.value !== appttoremove;
        });
        setMultiAppts(newAppts);
    };

    const handleRefreshAttendees = async (eventId) => {
        console.log('\n\n\nREFRESH ATTENDEES HAS BEEN CALLED', eventId);
        try {
            const newAttendees = await getAttendees(eventId);

            const { attendees } = newAttendees;
            if (attendees?.length > 0) {
                setAttendeeForThisEvent(attendees);
                setShowBookedClients(true);
                setHasInitialClientsInAppt(true);
            } else {
                setAttendeeForThisEvent([]);
                setShowBookedClients(false);
                setHasInitialClientsInAppt(false);
            }
        } catch (err) {
            console.log(
                'there was an issue refreshing the list of attendees',
                err
            );
        }
    };
    const handleCancel = () => {
        onCancel();
    };
    const handleApptSelection = (event, value) => {
        if (value) {
            setApptTypeSelected(value);
            setMinutesToAdd(value.minutes);
        }
        if (actionTaking === 'editing') {
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }

            setItemsChanged([...itemsChanged, 'apptType']);
        }
    };
    const handleChangeOfTimePicker = (event, label) => {
        if (label === 'start-time') {
            setManuallySetEnd(false);
            setStartTimeToPass(event);
            if (actionTaking === 'editing') {
                const diffMinutes = dayjs
                    .utc(endTimeToPass)
                    .diff(startTimeToPass, 'minutes');
                const newEndTime = dayjs.utc(event).add(diffMinutes, 'minutes');
                setEndTimeToPass(newEndTime);
            }
        } else if (label === 'end-time') {
            setManuallySetEnd(true);
            setEndTimeToPass(event);

            if (event < startTimeToPass) {
                // this resolves an issue where user NEEDS to select pm to ensure that the end time is after the start time
                // now, if they do not select PM, it is ok. It will automatically adjust the end time to be 12 hours later
                const newEndTimeAdjustForPM = dayjs.utc(event).add(12, 'hours');
                setEndTimeToPass(newEndTimeAdjustForPM);
            }
        }
        if (hasInitialClientsInAppt) {
            setClientsNeedAddtlNotifPrompt(true);
        }
        if (actionTaking === 'editing') {
            setItemsChanged([...itemsChanged, 'time']);
        }
    };

    const handleStartTimeChange = (event) => {
        setManuallySetEnd(false);
        if (actionTaking === 'editing') {
            const diffMinutes = dayjs
                .utc(endTimeToPass)
                .diff(startTimeToPass, 'minutes');
            const newEndTime = dayjs.utc(event).add(diffMinutes, 'minutes');
            setEndTimeToPass(newEndTime);
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }
            setItemsChanged([...itemsChanged, 'time']);
        }
        setStartTimeToPass(event);
    };
    const handleDateChange = (event) => {
        const newdate = dayjs.utc(event);
        const newdateformatted = newdate.format('M/D/YYYY');
        setDateToPass(newdateformatted);
        const hourEnd = dayjs.utc(endTimeToPass).hour();
        const minuteEnd = dayjs.utc(endTimeToPass).minute();
        const newEndTime = dayjs.utc(event).hour(hourEnd).minute(minuteEnd);
        setEndTimeToPass(newEndTime);
        const hourStart = dayjs.utc(startTimeToPass).hour();
        const minuteStart = dayjs.utc(startTimeToPass).minute();
        const newStartTime = dayjs
            .utc(event)
            .hour(hourStart)
            .minute(minuteStart);
        setStartTimeToPass(newStartTime);
        if (actionTaking === 'editing') {
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }
            setItemsChanged([...itemsChanged, 'date']);
        }
    };
    const handleEndTimeChange = (event) => {
        setManuallySetEnd(true);
        setEndTimeToPass(event);
        if (actionTaking === 'editing') {
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }
            setItemsChanged([...itemsChanged, 'time']);
        }
    };

    const handleInstructorSelection = (event, value) => {
        setInstructorSelected(value);
        if (actionTaking === 'editing') {
            if (hasInitialClientsInAppt) {
                setClientsNeedAddtlNotifPrompt(true);
            }
            setItemsChanged([...itemsChanged, 'instructor']);
        }
    };
    const handleClientSelection = (event) => {
        if (event) setClientSelected(event);

        if (actionTaking === 'editing') {
            setItemsChanged([...itemsChanged, 'client']);
        }
    };
    const handleSubmit = async () => {
        // get the date updated to include the date
        if (!isBlockingTime) {
            if (!apptTypeSelected && !atleastOneApptHasBeenSelected) {
                setAlert({
                    msg: 'Please select an appointment type before saving.',
                    seconds: 5,
                    warning: true,
                });
                return;
            }
        }

        if (!instructorSelected) {
            const msgtouse = `Please assign ${instructorPref} ${instructorAltName} to the ${isBlockingTime ? 'blocked time' : 'appointment'} before saving.`;
            setAlert({
                msg: msgtouse,
                seconds: 5,
                warning: true,
            });
            return;
        }
        let apptNameToUse;
        let apptdefaultpricetouse = 1;
        let completedAppt = true;
        if (!apptTypeSelected) {
            // last appt was not set in studio admin - so skip it
            if (isBlockingTime) {
                apptNameToUse = `Blocked Time - ${instructorSelected.label}`;
                apptdefaultpricetouse = 0;
            } else {
                apptNameToUse = 'Multiple Appts (see description)';
                completedAppt = false;
            }
        } else {
            apptNameToUse = apptTypeSelected
                ? apptTypeSelected.label
                : `Blocked Time - ${instructorSelected.label}`;
            apptdefaultpricetouse = apptTypeSelected
                ? apptTypeSelected.apptdefaultprice
                : 0;
        }
        // const apptNametoUse = apptTypeSelected
        //     ? apptTypeSelected.label
        //     : `Blocked Time - ${instructorSelected.label}`;

        const apptIdtouse = apptTypeSelected ? apptTypeSelected.value : null;
        const apptminutestouse = apptTypeSelected
            ? apptTypeSelected.minutes
            : 10;

        // if (!apptNametoUse) apptNametoUse = 'Blocked Time';
        let addtomessageresms;

        let apptToPass = {
            apptDefaultPrice: apptdefaultpricetouse,
            apptId: apptIdtouse,
            apptLength: apptminutestouse,
            apptName: apptNameToUse,
            apptType: apptNameToUse,
            apptOverridePrice: apptdefaultpricetouse,
            clientemail: clientSelected ? clientSelected.email : null,
            clientfirstname: clientSelected ? clientSelected.firstName : null,
            clientid: clientSelected ? clientSelected.id : null,
            clientlastname: clientSelected ? clientSelected.lastName : null,
            clientname: clientSelected ? clientSelected.label : null,
            createApptFromThisEntry: completedAppt,
            customerServicePhone: customerServicePhone
                ? customerServicePhone
                : null,
            dibsStudioId,
            endTimeToPass: dayjs
                .utc(endTimeToPass)
                .format('YYYY-MM-DD HH:mm:ss'),
            eventid: selectedEvent ? selectedEvent.eventid : null,
            instructorid: instructorSelected.value,
            instructorname: instructorSelected.label,
            isBlockingTime,
            itemsChanged,
            mainTimezone: timeZone,
            mobilephone: clientSelected ? clientSelected.phone : null,
            originalStartTimeUnFormatted: startTimeToPass,
            isMultiAppts: multiAppts?.length > 0 ? true : false,
            priceAppt: apptdefaultpricetouse,
            startTimeToPass: dayjs
                .utc(startTimeToPass)
                .format('YYYY-MM-DD HH:mm:ss'),
            timeOfFirstAppt: dayjs
                .utc(timeOfFirstApptInCaseOfMulti)
                .format('YYYY-MM-DD HH:mm:ss'),
            studioEmployeeId,
            studioName: studioName,
            twilioCustomNumUS: twilioCustomNumUs ? twilioCustomNumUs : null,
            userid: clientSelected ? clientSelected.id : null,
        };

        if (actionTaking === 'creating') {
            setIsSubmitting(true);

            await createNewAppt(apptToPass, multiAppts)
                .then(async (res) => {
                    console.log(
                        '\n\n\nThe Title to display in email is',
                        res.titleToDisplayInEmail
                    );
                    const {
                        success,
                        instructorConflict,
                        message,
                        titleToDisplayInEmail,
                        isMulti,
                    } = res;
                    if (instructorConflict) {
                        if (
                            message ===
                            'Instructor is not available at this time'
                        ) {
                            setAlert({
                                msg: `${apptToPass.instructorname} is not available at this time. Please choose a different ${instructorAltName} or a different time. Click 'Show availability' to see the ${instructorAltName}'s schedule.`,
                                seconds: 10,
                                error: true,
                            });
                        } else {
                            setAlert({
                                msg: `There is a conflict with ${apptToPass.instructorname}'s schedule. Please choose a different ${instructorAltName} or a different time. Click 'Show availability' to see the ${instructorAltName}'s schedule.`,
                                seconds: 10,
                                error: true,
                            });
                        }

                        setIsSubmitting(false);
                        return;
                    }
                    if (success) {
                        // if there is a user - add the dibstransaction and user to attendees table
                        if (clientSelected) {
                            const { data } = res;
                            const { eventid } = data[0];
                            apptToPass = {
                                ...apptToPass,
                                eventid,
                                fromLocation: 'studio-admin',
                            };
                            const email = clientSelected.email;

                            const apptCreated = await addUserToAppt(apptToPass);

                            if (apptCreated) {
                                const { status } = apptCreated;
                                if (status === 1) {
                                    sendOpsEmail({
                                        userid: clientSelected.id,
                                        dibsId: dibsStudioId,
                                        eventid,
                                        typeofevent: 'booking new appt',
                                        subject: `Booked New Appt @ ${dibsStudioId}`,
                                        relevantId: eventid,
                                        idStringName: 'Eventid',
                                        action: 'created',
                                        studioEmployeeId,
                                        extraInfo: `This is coming from the schedule calendar - apptFields page - line 572`,
                                        fromWhere: `Booked on schedule calendar - studio admin`,
                                    }); // send an email to the ops team
                                    if (sendEmailConfirmation) {
                                        // send email confirmation
                                        const datatosend = {
                                            appttocreate: {
                                                apptToConfirm: apptToPass,
                                                email,
                                                dibsId: dibsStudioId,
                                                titleToDisplayInEmail,
                                                isMulti,
                                            },
                                            eventscreated: [
                                                {
                                                    eventid,
                                                },
                                            ],
                                        };

                                        const confirmationSent =
                                            await sendConfirmationEmail(
                                                datatosend
                                            );
                                    }
                                }
                            }

                            if (sendSMSConfirmation && sendSMSForTesting) {
                                // send SMS confirmation
                                const datatosend = {
                                    appttocreate: {
                                        apptToConfirm: apptToPass,
                                        studioEmployeeId,
                                        dibsId: dibsStudioId,
                                        eventtype: 'appt',
                                        isMulti:
                                            multiAppts?.length > 0
                                                ? true
                                                : false,
                                    },
                                    eventscreated: [
                                        {
                                            eventid,
                                        },
                                    ],
                                };

                                const res =
                                    await sendSmsConfirmation(datatosend);
                                const { code, msg } = res;
                                if (code) {
                                    console.log('code returned was', code);
                                    console.log(
                                        'msg received from sms send is\n',
                                        msg
                                    );
                                    if (code === 5) {
                                        addtomessageresms = true;
                                    }
                                }
                            }
                            setIsSubmitting(false);
                        }
                        let msgtoshow = `Successfully created the ${isBlockingTime ? 'blocked time' : 'appointment'}.`;
                        let statustoshow = 'success';
                        let secondstoshow = 5;
                        if (addtomessageresms) {
                            msgtoshow = `${msgtoshow} \n\nNote: SMS could not be sent because there is not a phone number on file for ${clientSelected?.label}.`;
                            statustoshow = 'warning';
                            secondstoshow = 30;
                        }
                        setAlert({
                            msg: msgtoshow,
                            seconds: secondstoshow,
                            // success: true,
                            [statustoshow]: true,
                        });
                        setIsSubmitting(false);
                        setUpdateEvents(true);
                        refreshCalendar();
                        dispatch(getEvents(dibsStudioId, timeZone));
                        onCancel(); // this just closes the modal. if we add more to this function, this needs to be changed. It comes from calendar. It is handleModalClose function.
                    } else {
                        setAlert({
                            msg: `Could not create the ${isBlockingTime ? 'blocked time' : 'appointment'}`,
                            seconds: 5,
                            error: true,
                        });
                        return;
                    }
                })
                .catch((error) => {
                    console.log('could not create the appt', error);
                });
        } else {
            // appt is being updated
            if (apptToPass.itemsChanged?.length === 0) {
                onCancel();
                return;
            } else {
                try {
                    await updateExistingEvent(
                        apptToPass,
                        eventidSelected,
                        sendEmailConfirmation,
                        'apptfields - updated on calendar page - studio admin',
                        studioEmployeeId,
                        dibsStudioId
                    );

                    setUpdateAllEvents(true);

                    const attendeeList = await getAttendees(eventidSelected);
                    const { attendees } = attendeeList;
                    const attendeeListNew = attendees.map((a) => {
                        let smsAvailable = false;
                        const { mobilephone } = a;
                        if (mobilephone) {
                            if (mobilephone?.length > 2) {
                                smsAvailable = true;
                            }
                        }

                        return {
                            ...a,
                            emailIsChecked: true,
                            smsIsChecked: true,
                            shouldSendEmail: true,
                            shouldSendSms: true,
                            smsIsAvailable: smsAvailable,
                        };
                    });

                    if (attendeeListNew?.length > 0) {
                        setAttendeeForThisEvent(attendeeListNew);

                        if (clientsNeedAddtlNotifPrompt) {
                            const attendeeListFiltered = attendeeListNew.filter(
                                (a) => a.userid !== clientSelected?.id
                            ); // removing current client selected bc they have just been added and do not need to be notified of the change

                            setAttendeesAffected(attendeeListFiltered);
                        } else {
                            setAttendeesAffected([]);
                            setAlert({
                                msg: `Successfully updated the appointment`,
                                seconds: 5,
                                success: true,
                            });
                        }
                        setEventIdEditing(eventidSelected);
                        setShowAlertWithEmailSms(true);
                        const changedItems = apptToPass.itemsChanged;

                        if (changedItems.includes('client')) {
                            const email = clientSelected.email;

                            const datatosend = {
                                appttocreate: {
                                    apptToConfirm: apptToPass,
                                    email,
                                    dibsId: dibsStudioId,
                                    eventtype: 'appt',
                                },
                                eventscreated: [
                                    {
                                        eventid: eventidSelected,
                                    },
                                ],
                            };
                            if (sendSMSConfirmation) {
                                sendSmsConfirmation(datatosend);
                                console.log(
                                    '\n\nclient changed --- sms - make sure this creates a communication log'
                                );
                                console.log(
                                    '\n\nalso, may need to confirm that the right people are being notified - added vs removed client etc'
                                );
                            }
                            if (sendEmailConfirmation) {
                                sendConfirmationEmail(datatosend);
                                console.log(
                                    '\n\nclient changed --- email - make sure this creates a communication log'
                                );
                                console.log(
                                    '\n\nemail - also, may need to confirm that the right people are being notified - added vs removed client etc'
                                );
                            }
                        }
                    } else {
                        setAlert({
                            msg: 'Successfully updated the appointment',
                            seconds: 5,
                            success: true,
                        });
                    }
                    setUpdateEvents(true);
                    setClientsNeedAddtlNotifPrompt(false);
                    setHasInitialClientsInAppt(false);
                    setItemsChanged([]);
                    onCancel(); // this just closes the modal. if we add more to this function, this needs to be changed. It comes from calendar. It is handleModalClose function.
                } catch (err) {
                    console.log('error updating the event - apptFields', err);
                    setAlert({
                        msg: 'Could not update the appointment. Please refresh your screen and try again.',
                        seconds: 5,
                        error: true,
                    });
                }
            }
        }
    };
    const handleChangeWasMade = () => {
        setUpdateAllEvents(true);
    };

    const showNotes = () => {
        if (selectedEvent) {
            const { dbDescription } = selectedEvent;
            if (!dbDescription) {
                return false;
            } else {
                if (dbDescription.length > 3) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };
    const shouldShowNotes = showNotes();

    // selectedEvent && selectedEvent.dbDescription.length;
    return (
        <div id="container-create-appt">
            <Grid container justifyContent="center">
                {addingAnotherAppt && (
                    <Grid
                        item
                        sx={{
                            mb: 5,
                            mt: 1,
                            // position: 'sticky',
                            // top: 20, // Adjust as needed, this sets the distance from the top of the viewport
                            // zIndex: 1000, // Ensures it stays above other content
                            backgroundColor: '#f5f5f5', // Same as background for consistency
                        }}
                        border={0}
                        xs={11.5}
                        md={9.5}
                        id="summary-of-appt-being-created"
                    >
                        <SummaryApptBeingCreated
                            clientSelected={clientSelected}
                            multiAppts={multiAppts}
                            dateToPass={dateToPass}
                            clickRemove={removeApptFromMulti}
                        />
                    </Grid>
                )}

                {!isBlockingTime && (
                    <Grid
                        item
                        xs={11.5}
                        md={9.5}
                        id="appt-type-item-add-event-appt"
                        // border={2}
                        sx={{ p: 0, pt: 1, mb: 2 }}
                    >
                        <AutocompleteFieldUI
                            title="Appointment Type"
                            options={selectionOptions}
                            changeAction={handleApptSelection}
                            valueSelected={apptTypeSelected}
                        />
                    </Grid>
                )}

                <Grid
                    item
                    xs={11.5}
                    md={9.5}
                    id="instructor-name-item-add-event"
                    // border={2}
                    sx={{ p: 0, pt: 1, mb: 2 }}
                >
                    <AutocompleteFieldUI
                        title={instructorTitle}
                        options={instructorOptions}
                        changeAction={handleInstructorSelection}
                        valueSelected={instructorSelected}
                    />
                </Grid>
                <Grid item xs={11.5} md={9.5} id="divider-appt-fields">
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid
                    item
                    xs={11.5}
                    md={9.5}
                    // border={2}
                    id="start-date-picker-appt"
                    sx={{ p: 0, m: 0, mb: 2 }}
                >
                    <DatePicker
                        initialStartDate={initialStartDate}
                        changeAction={handleDateChange}
                        valueSelected={dateToPass}
                        isDisabled={addingAnotherAppt}
                    />
                </Grid>
                <Grid item xs={11.5} md={9.5} id="divider-grid-appt-fields">
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid
                    item
                    xs={11.5}
                    // border={2}
                    md={9.5}
                    id="start-date-time-picker-appt"
                    sx={{ p: 0, pt: 1, mb: 2 }}
                >
                    <Grid container id="container-for-timepicker-start">
                        <Grid
                            item
                            xs={5}
                            sx={{ mr: 1 }}
                            id="holder-timepicker-start"
                        >
                            <Grid item xs={11.5} md={9.5} id="timepicker-start">
                                <TimePicker
                                    label="Start Time"
                                    keylabel="start-time"
                                    value={startTimeToPass}
                                    defaultTimeValue={startTimeToPass}
                                    acceptAction={handleStartTimeChange}
                                    changeAction={handleChangeOfTimePicker}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={5} id="holder-end-time-picker">
                            <Grid item xs={11.5} md={9.5} id="timepicker-end">
                                <TimePicker
                                    label="End Time"
                                    keylabel="end-time"
                                    value={endTimeToPass}
                                    defaultTimeValue={endTimeToPass}
                                    changeAction={handleChangeOfTimePicker}
                                    acceptAction={handleEndTimeChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11.5} md={9.5}>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                {showBookedClients && (
                    <>
                        <Grid
                            item
                            xs={11.5}
                            md={9.5}
                            sx={{ p: 0, pt: 1, mb: 2 }}
                            id={`booked-clients-grid-item-appts-${eventidSelected}`}
                        >
                            <Typography>Booked Client(s)</Typography>
                            <BookedClients
                                attendees={attendeeForThisEvent}
                                setHideSaveButtons={setHideSaveButtons}
                                thiseventid={eventidSelected}
                                dibsId={dibsStudioId}
                                startTime={startTimeToPass}
                                setUpdateEvents={setUpdateEvents}
                                handleChangeWasMade={handleChangeWasMade}
                                setClientSelected={handleClientSelection}
                                handleClientSelection={handleClientSelection}
                                refreshAttendees={handleRefreshAttendees}
                            />
                        </Grid>
                    </>
                )}
                {shouldShowNotes && (
                    <>
                        <Grid
                            item
                            xs={11.5}
                            md={9.5}
                            sx={{ p: 0, pt: 1, mb: 2 }}
                            id={`selected-notes-grid-item-appts-${eventidSelected}`}
                        >
                            <Divider sx={{ mt: 0, mb: 2.5 }} />
                            <Typography sx={{ mb: 0.5 }}>Notes:</Typography>
                            {/* <Typography variant="h8">
                                {selectedEvent.dbDescription}
                            </Typography> */}
                            <Typography variant="h8" component="div">
                                {selectedEvent.dbDescription
                                    .split('<br />')
                                    .map((part, index) => (
                                        <React.Fragment key={index}>
                                            {part}
                                            {index !==
                                                selectedEvent.dbDescription.split(
                                                    '<br />'
                                                ).length -
                                                    1 && <br />}
                                        </React.Fragment>
                                    ))}
                            </Typography>
                        </Grid>
                    </>
                )}
                {!showAutoCompleteClient && (
                    <Grid item xs={11.5} md={9.5} sx={{ p: 0, pt: 1, mb: 2 }}>
                        <Typography>Selected Client</Typography>
                        <BookedClientsPotentially
                            attendees={attendeeForThisEvent}
                            setHideSaveButtons={setHideSaveButtons}
                            thiseventid={eventidSelected}
                            dibsId={dibsStudioId}
                            startTime={startTimeToPass}
                            setUpdateEvents={setUpdateEvents}
                            setAttendeeForThisEvent={setAttendeeForThisEvent}
                            setShowAutoCompleteClient={
                                setShowAutoCompleteClient
                            }
                            setClientSelected={setClientSelected}
                        />
                    </Grid>
                )}
                {isAddingNewClient && (
                    <>
                        <Grid
                            item
                            xs={11.5}
                            border={0}
                            md={9.5}
                            sx={{ px: 0.85 }}
                        >
                            <NewClientAccount
                                setHideSaveButtons={setHideSaveButtons}
                                setIsAddingNewClient={setIsAddingNewClient}
                                handleClientSelection={handleClientSelection}
                                setAttendeeForThisEvent={
                                    setAttendeeForThisEvent
                                }
                                setShowAutoCompleteClient={
                                    setShowAutoCompleteClient
                                }
                            />
                        </Grid>
                        <Grid item xs={11.5} md={9.5}>
                            <Divider sx={{ mt: 1, mb: 2 }} />
                        </Grid>
                    </>
                )}
                {!showBookedClients &&
                    !isBlockingTime &&
                    !isAddingNewClient &&
                    showAutoCompleteClient && (
                        <>
                            <Grid
                                item
                                xs={11.5}
                                md={9.5}
                                id="add-client-item-appt"
                                sx={{ p: 0, pt: 1, mb: 2 }}
                            >
                                <AutocompleteField
                                    title="Add A Client (Optional)"
                                    onChangeAction={handleClientSelection}
                                    setIsAddingNewClient={setIsAddingNewClient}
                                    clientSelected={clientSelected}
                                    setClientSelected={setClientSelected}
                                />
                            </Grid>
                            {showErrorGuidance && (
                                <Grid item xs={11.5} md={9.5}>
                                    <Grid container justifyContent="flex-end">
                                        <Typography variant="errorText">
                                            {instructorSelected?.firstname}{' '}
                                            {errorMsgToShow ||
                                                'has a conflict with this appointment time so it cannot be scheduled.'}
                                            {/* TODO - Might need to update this to handle cases of multiple service providers */}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                            {clientSelected &&
                                apptTypeSelected &&
                                instructorSelected && (
                                    <Grid
                                        item
                                        xs={11.5}
                                        md={9.5}
                                        border={0}
                                        sx={{ p: 0, pt: 0, mb: 2 }}
                                    >
                                        <AddAnotherItem
                                            textToDisplay={`Add another Appt For ${clientSelected?.firstName}`}
                                            submitAction={async () => {
                                                const scrollToTopOfComponent =
                                                    () => {
                                                        const element =
                                                            document.getElementById(
                                                                'new-appt-container'
                                                            );
                                                        if (element) {
                                                            element.scrollIntoView(
                                                                {
                                                                    behavior:
                                                                        'smooth',
                                                                }
                                                            );
                                                        }
                                                    };

                                                const newAppt = {
                                                    clientSelected,
                                                    apptTypeSelected,
                                                    instructorSelected,
                                                    startTimeToPass,
                                                    endTimeToPass,
                                                    createApptFromThisEntry: true,
                                                };

                                                // here is where we should check to see if instructor is available
                                                const isavailable =
                                                    await confirmInstructorIsAvailable(
                                                        newAppt,
                                                        multiAppts
                                                    );
                                                const { success, errorMsg } =
                                                    isavailable;

                                                if (!success) {
                                                    setErrorMsgToShow(errorMsg);
                                                    setShowErrorGuidance(true);

                                                    setTimeout(() => {
                                                        setShowErrorGuidance(
                                                            false
                                                        );
                                                        setErrorMsgToShow('');
                                                    }, 5000);
                                                } else {
                                                    const tempArray = [
                                                        ...multiAppts,
                                                    ]; // Make a copy of the state
                                                    tempArray.push(newAppt); // Push the new item
                                                    setMultiAppts(tempArray);
                                                    setAtleastOneApptHasBeenSelected(
                                                        true
                                                    );
                                                    dispatch(
                                                        setApptsBeingConfirmed(
                                                            multiAppts
                                                        )
                                                    );
                                                    setStartTimeToPass(
                                                        endTimeToPass
                                                    );
                                                    setAddingAnotherAppt(true);
                                                    setApptTypeSelected(null);
                                                    scrollToTopOfComponent();
                                                }
                                            }}
                                        />
                                    </Grid>
                                )}
                        </>
                    )}
                <Grid item xs={11.5} md={9.5} id="divider-line-1150">
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid
                    item
                    xs={11.5}
                    md={9.5}
                    sx={{ p: 0, pt: 1, mb: 2 }}
                    id="holder-of-send-email-sms-confirmations"
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendEmailConfirmation}
                                    disabled={!clientSelected}
                                    onChange={(event) => {
                                        setSendEmailConfirmation(
                                            event.target.checked
                                        );
                                        if (!sendEmailConfirmation) {
                                            setEmailLabel(
                                                'Send Email Confirmation'
                                            );
                                        } else {
                                            setEmailLabel(
                                                'Will Not Send Email Confirmation'
                                            );
                                        }
                                    }}
                                />
                            }
                            label={emailLabel}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={sendSMSConfirmation}
                                    disabled={!clientSelected}
                                    onChange={(event) => {
                                        setSendSMSConfirmation(
                                            event.target.checked
                                        );
                                        if (!sendSMSConfirmation) {
                                            setSMSLabel(
                                                'Send SMS/Text Confirmation'
                                            );
                                        } else {
                                            setSMSLabel(
                                                'Will Not Send SMS/Text Confirmation'
                                            );
                                        }
                                    }}
                                />
                            }
                            label={smsLabel}
                        />
                    </FormGroup>
                </Grid>
                {!hideSaveButtons && !isAddingNewClient && (
                    <Grid item xs={11.5} md={9.5}>
                        <Grid container justifyContent="flex-end">
                            <Grid item sx={{ mr: 2 }}>
                                <Button
                                    id="cancel-appt-button-fields"
                                    variant="cancel"
                                    sx={{ mt: 1, mb: 2 }}
                                    onClick={handleCancel}
                                >
                                    {cancelText}
                                </Button>
                            </Grid>
                            <Grid
                                item
                                sx={{ mr: 1 }}
                                id="create-or-edit-appt-button"
                            >
                                <LoadingButton
                                    disableElevation
                                    loading={isSubmitting}
                                    variant="contained"
                                    color="primary"
                                    id="save-appt-button-loading"
                                    onClick={handleSubmit}
                                    sx={{
                                        bgcolor:
                                            theme.palette.globalcolors.submit,
                                        '&:hover': {
                                            backgroundColor:
                                                theme.palette.globalcolors
                                                    .hoverSubmit,
                                        },
                                        mt: 1,
                                        mb: 2,
                                    }}
                                >
                                    {buttonText}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ApptFields;
