import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pageIsLoading: false,
    loaderShouldBeDarker: false,
    showOptionToReturnToSchedule: false,
};

const menu = createSlice({
    name: 'loadingstatus',
    initialState,
    reducers: {
        setPageIsLoading(state, action) {
            return {
                ...state,
                pageIsLoading: action.payload,
            };
        },
        setLoaderShouldBeDarker(state, action) {
            return {
                ...state,
                loaderShouldBeDarker: action.payload,
            };
        },
        setShowOptionToReturnToSchedule(state, action) {
            return {
                ...state,
                showOptionToReturnToSchedule: action.payload,
            };
        },
    },
});

export default menu.reducer;
export const {
    setPageIsLoading,
    setLoaderShouldBeDarker,
    setShowOptionToReturnToSchedule,
} = menu.actions;
