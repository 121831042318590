import axios from 'axios';

export default async function createNewCustomerIdStripe(props) {
    console.log('\n\n\n\ncreate new customer stripe ID action is called');

    try {
        const baseURL = process.env.REACT_APP_BASE_URL;
        const basicurl = `${baseURL}/stripe/admin/create-new-id`;

        const response = await axios.post(basicurl, props);
        const { data } = response;
        if (!data) {
            return null;
        }
        return data;
    } catch (err) {
        console.log(`error from creating new stripe ID for dibs admin: ${err}`);
        return err;
    }
}
