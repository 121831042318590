// third-party
import { createSlice } from '@reduxjs/toolkit';
import parsePhoneNumber, { findPhoneNumbersInText } from 'libphonenumber-js';
import dayjs from 'dayjs';
import { lowercaseString } from 'helpers';

// ----------------------------------------------------------------------

const formatDate = (date) => {
    const fromunix = dayjs.unix(date);
    return dayjs(fromunix).format('M/D/YYYY h:mm A');
};
const formatDateDb = (date) => {
    const fromunix = dayjs.unix(date);
    return dayjs(fromunix).format('YYYY-MM-DD H:mm:ss');
};

const initialState = {
    config: {
        dibsStudioId: 0,
        studioName: '',
        timeZone: '',
        wdth: 1000,
        country: 'US',
        currencyCode: 'USD',
    },
    studioConfig: {
        terms: '',
        color: 'eeeeee',
        instructorAltName: 'instructor',
        instructorPref: 'an',
        classesAltName: 'classes',
        classAltName: 'class',
        entryPage: 'schedule',
        intervalEnd: 14,
        autopayNotice: true,
        use_spot_booking: '',
        showApptsOnAdmin: false,
        showApptsOnWidget: false,
        show_credit_load: true,
        showChatbot: false,
        first_class_fixed_price: null,
        display_giftcards: true,
        raf_award: 5,
        spot_label: '',
        vod_access_period: 48,
        showPricesWidget: true,
        imageUrls: {
            color_logo: '',
            hero_url: '',
        },
        hasMemberships: true,
        salesTax: 0,
        retailTax: 0,
        locationIdShowing: 0,
        locationids: [],
        billing: {
            billing_contact: '',
            billing_email: '',
            stripeid: '',
            stripeid_test: '',
            stripeid_prod: '',
            stripe_account_id: '',
            stripe_cardid: '',
            pmId: '',
            subscription_fee: 0.0,
            total_monthly_charge: 0.0,
            date_of_charge: '',
            subscriptionId: '',
            subscriptionInfo: {
                id: '',
                hasHadSubscriptionBefore: true,
                isActive: false,
                current_period_start: null,
                current_period_end: null,
                default_payment_method: null,
                default_source: null,
                ended_at: null,
                planid: '',
                planAmount: 0,
                discount: 0,
                nextChargeDate: null,
                trialend: null,
                onFreeTrial: true,
                numTrialDays: 0,
                canceledOn: null,
                originallyStarted: null,
                canceledBy: null,
                willEndAtEndOfPeriod: false,
                wasCanceled: false, //must have been active at one point to be canceled
            },
            isEligibleForForeverFreePlan: false,
            subscriptionInfoUpdatedAt: null,
        },
        openTime: '08:00',
        closeTime: '20:00',
        apptExceptions: [],
    },
    customerService: {
        customerServiceEmail: '',
        customEmailToSendFrom: '',
        customerServicePhone: '',
        customerServicePhoneObject: {},
        twilioCustomNumUs: '',
        address: '',
    },
    settings: {
        dynamicPricing: false,
        flashCredits: false,
        minPrice: 20,
        maxPrice: 30,
        showChatbot: false,
        businessHours: [],
    },
    integrations: {
        classpass: false,
        gymPass: false,
        cp_amount: 0,
        oldstudioid: 0,
        shopify: false,
    },
    locationInfo: [],
};

const dibsstudio = createSlice({
    name: 'dibsstudio',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            return {
                ...state,
                error: action.payload,
            };
        },
        // ADD STUDIO DATA
        addStudioData(state, action) {
            return {
                ...state,
                config: action.payload,
            };
        },
        setCountry(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    country: action.payload,
                },
            };
        },
        setLocationInfo(state, action) {
            const locInfo = action.payload;
            const arrayholder = [];
            locInfo.forEach((element) => {
                arrayholder.push(element);
            });

            return {
                ...state,
                locationInfo: arrayholder,
            };
        },

        setCurrencyCode(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    currencyCode: action.payload,
                },
            };
        },
        setShowApptsOnWidget(state, action) {
            let actionShowOnAdminHere = state.studioConfig.showApptsOnAdmin;

            if (action.payload) {
                actionShowOnAdminHere = true;
            }
            return {
                ...state,
                config: {
                    ...state.config,
                    showAppointments: action.payload,
                },
                studioConfig: {
                    ...state.studioConfig,
                    showApptsOnWidget: action.payload,
                    showApptsOnAdmin: actionShowOnAdminHere,
                },
            };
        },
        setShowApptsOnAdmin(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    showAppointments: action.payload,
                },
                studioConfig: {
                    ...state.studioConfig,
                    showApptsOnAdmin: action.payload,
                },
            };
        },
        setWdth(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    wdth: action.payload,
                },
            };
        },
        setBusinessHours(state, action) {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    businessHours: action.payload,
                },
            };
        },
        setLocationIds(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    locationids: action.payload,
                },
            };
        },
        setLocationIdShowing(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    locationIdShowing: action.payload,
                },
            };
        },
        setOpenTime(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    openTime: action.payload,
                },
            };
        },
        setCloseTime(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    closeTime: action.payload,
                },
            };
        },
        setApptExceptions(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    apptExceptions: action.payload,
                },
            };
        },
        setShowPrices(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    showPricesWidget: action.payload,
                },
            };
        },
        setDibsStudioId(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    dibsStudioId: action.payload,
                },
            };
        },
        setGympass(state, action) {
            state.integrations.gympass = action.payload;
        },
        setEntryPage(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    entryPage: action.payload,
                },
            };
        },
        setClasspass(state, action) {
            state.integrations.classpass = action.payload;
        },
        setStudioName(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    studioName: action.payload,
                },
            };
        },
        setPaymentInfo(state, action) {
            const pmInfo = action.payload;

            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        ...pmInfo,
                    },
                },
            };
        },

        setPaymentContactInfo(state, action) {
            const { billing_contact, billing_email } = action.payload;

            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        billing_contact,
                        billing_email,
                    },
                },
            };
        },
        setPaymentCardId(state, action) {
            const pmId = action.payload;

            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        pmId,
                        stripe_cardid: pmId,
                    },
                },
            };
        },

        setSubscriptionInfo(state, action) {
            const info = action.payload;

            const {
                id,
                isActive,
                current_period_start,
                current_period_end,
                default_payment_method,
                default_source,
                planid,
                planAmount,
                discount,
                nextChargeDate,
                trialend,
                onFreeTrial,
                numTrialDays,
            } = info;
            if (!id) return state;
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        subscriptionInfo: {
                            id,
                            isActive:
                                isActive ||
                                state.studioConfig.billing.subscriptionInfo
                                    .isActive,
                            current_period_start,
                            current_period_end,
                            planAmount,
                            discount,
                            onFreeTrial,
                            numTrialDays,
                        },
                        subscriptionInfoUpdatedAt: dayjs(),
                    },
                },
            };
        },
        setHashadSubscriptionBefore(state, action) {
            const { hasHadSubscriptionBefore } = action.payload;
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        subscriptionInfo: {
                            ...state.studioConfig.billing.subscriptionInfo,
                            hasHadSubscriptionBefore,
                        },
                        subscriptionInfoUpdatedAt: dayjs(),
                    },
                },
            };
        },
        cancelSubscriptionRedux(state, action) {
            const {
                canceledBy,
                subscriptionId,
                canceledOn,
                willEndAtEndOfPeriod,
                wasCanceled,
            } = action.payload;
            console.log('subscription id being canceled is', subscriptionId);
            if (!subscriptionId) return state;
            const currentid = state.studioConfig.billing.subscriptionInfo.id;
            if (currentid !== subscriptionId) return state;
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        subscriptionInfo: {
                            ...state.studioConfig.billing.subscriptionInfo,
                            canceledBy,
                            canceledOn,
                            willEndAtEndOfPeriod,
                            wasCanceled,
                        },
                        subscriptionInfoUpdatedAt: dayjs(),
                    },
                },
            };
        },
        setSubscriptionId(state, action) {
            const subid = action.payload;
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        subscriptionId: subid,
                        subscriptionInfo: {
                            ...state.studioConfig.billing.subscriptionInfo,
                            id: subid,
                        },
                        subscriptionInfoUpdatedAt: dayjs(),
                    },
                },
            };
        },
        setSubscriptionInfoFull(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        subscriptionInfo: action.payload,
                        subscriptionInfoUpdatedAt: dayjs(),
                    },
                },
            };
        },
        setSubscriptionUpdatedDate(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        subscriptionInfoUpdatedAt: dayjs(),
                    },
                },
            };
        },
        updateSubscriptionInfo(state, action) {
            const subInfo = action.payload;
            const {
                isActive,
                planid,
                current_period_start,
                current_period_end,
                discount,
                created,
                amount,
                trial_end,
                ended_at,
                willEndAtEndOfPeriod,
                wasCanceled,
            } = subInfo;
            console.log('\n\nthe plan amount coming in to redux is', amount);

            const trialEndDate = dayjs.unix(trial_end);

            // Get the current date/time as a dayjs object
            const now = dayjs();

            // Use dayjs methods to compare dates.
            // For example, if you want to check whether the current time is before the trial end:
            const onFreeTrial = now.isBefore(trialEndDate);

            // If you need a formatted version of trial_end for display:
            const formattedTrialEnd = trialEndDate.format('M/D/YYYY h:mm A');

            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        subscription_fee: amount,
                        total_monthly_charge: amount,
                        date_of_charge: formatDate(current_period_end),
                        subscriptionInfo: {
                            ...state.studioConfig.billing.subscriptionInfo,
                            ...subInfo,
                            isActive:
                                isActive ||
                                state.studioConfig.billing.subscriptionInfo
                                    .isActive,
                            planid,
                            current_period_start:
                                formatDate(current_period_start),
                            current_period_end: formatDate(current_period_end),
                            current_period_end_db:
                                formatDateDb(current_period_end),
                            discount,
                            originallyStarted: formatDate(created),
                            planAmount: amount,
                            onFreeTrial,
                            trialend: formattedTrialEnd,
                            ended_at,
                            willEndAtEndOfPeriod,
                            wasCanceled,
                        },
                        subscriptionInfoUpdatedAt: dayjs(),
                    },
                },
            };
        },
        setAdminStripeId(state, action) {
            const stripeid = action.payload;
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        stripeid: stripeid,
                    },
                },
            };
        },
        setAdminAllStripeIds(state, action) {
            const { stripeid, stripeid_prod, stripeid_test } = action.payload;
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        stripeid: stripeid,
                        stripeid_prod: stripeid_prod,
                        stripeid_test: stripeid_test,
                    },
                },
            };
        },
        setAdminAccountStripeId(state, action) {
            const stripeAccountId = action.payload;
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    billing: {
                        ...state.studioConfig.billing,
                        stripe_account_id: stripeAccountId,
                    },
                },
            };
        },
        setColor(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    color: action.payload,
                },
            };
        },
        setDynamicPricing(state, action) {
            state.settings.dynamicPricing = action.payload;
        },
        setGlobalPrices(state, action) {
            state.settings.minPrice = action.payload.minPrice;
            state.settings.maxPrice = action.payload.maxPrice;
        },
        // setStudioProfileAccountInfo(state, action) {
        //     console.log('updating studio profile account info in redux');
        //     console.log('action payload is', action.payload);
        //     state.config.firstName = action.payload.firstname;
        //     state.config.lastName = action.payload.lastname;
        //     state.config.email = action.payload.email;
        //     state.config.phone = action.payload.phone;
        // },
        setGeneralLocationData(state, action) {
            state.customerService.customerServiceEmail =
                action.payload.serviceEmail;
            state.customerService.customerServicePhone =
                action.payload.servicePhone;
            state.customerService.address = action.payload.address;
            state.customerService.address2 = action.payload.address2;
            state.customerService.city = action.payload.city;
            state.customerService.state = action.payload.state;
            state.customerService.zipcode = action.payload.zipcode;
            state.studioConfig.salesTax = action.payload.salesTax;
            state.studioConfig.retailTax = action.payload.retailTax;
        },
        setCustomEmailToSendFrom(state, action) {
            state.customerService.customEmailToSendFrom = action.payload;
        },

        setTaxesRedux(state, action) {
            state.studioConfig.salesTax = action.payload.salesTax;
            state.studioConfig.retailTax = action.payload.retailTax;
        },
        setNumDaysToShowCalendar(state, action) {
            state.studioConfig.intervalEnd = action.payload;
        },
        setTwilioCustomNumUS(state, action) {
            state.customerService.twilioCustomNumUs = action.payload;
            state.config.customTwilioNumUS = action.payload;
        },
        setRafAwardRedux(state, action) {
            state.studioConfig.raf_award = action.payload;
        },
        setStudioConfigData(state, action) {
            const {
                autopayNotice,
                terms,
                color,
                display_giftcards,
                first_class_fixed_price,
                interval_end,
                raf_award,
                show_credit_load,
                spot_label,
                use_spot_booking,
                vod_access_period,
            } = action.payload;
            state.studioConfig.intervalEnd = interval_end;
            state.studioConfig.raf_award = raf_award;
            state.studioConfig.autopayNotice = autopayNotice;
            state.studioConfig.color = color;
            state.studioConfig.display_giftcards = display_giftcards;
            state.studioConfig.first_class_fixed_price =
                first_class_fixed_price;
            state.studioConfig.show_credit_load = show_credit_load;
            state.studioConfig.spot_label = spot_label;
            state.studioConfig.use_spot_booking = use_spot_booking;
            if (vod_access_period)
                state.studioConfig.vod_access_period = vod_access_period;
            if (terms) state.studioConfig.terms = terms;
        },
        setStudioImageUrls(state, action) {
            if (action.payload) state.studioConfig.imageUrls = action.payload;
        },
        setCustomerServiceEmail(state, action) {
            const { custServEmail, sendFromEmail } = action.payload;
            if (action.payload) {
                state.customerService.customerServiceEmail = custServEmail;
                state.customerService.customEmailToSendFrom = sendFromEmail;
            }
        },
        setTerms(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    terms: action.payload,
                },
            };
        },
        setCustomerServicePhone(state, action) {
            const phoneComingIn = action.payload;
            if (phoneComingIn) {
                const parsedPhoneNumber = parsePhoneNumber(phoneComingIn, 'US');
                const phoneisvalid = parsedPhoneNumber.isValid();
                if (phoneisvalid) {
                    const phoneNumberObject = findPhoneNumbersInText(
                        phoneComingIn,
                        'US'
                    );
                    const { number } = phoneNumberObject[0];
                    const numberFormatted = parsedPhoneNumber.formatNational();
                    state.customerService.customerServicePhone =
                        numberFormatted;
                    state.customerService.customerServicePhoneObject = number;
                }
            }
        },
        setInstructorAltName(state, action) {
            if (
                action.payload === 'undefined' ||
                action.payload === undefined
            ) {
                console.log(
                    'instructor alt name is undefined - need to reject'
                );
                return {
                    state,
                };
            }
            const passedinvalue = lowercaseString(action.payload);
            let donotupdate =
                passedinvalue === 'undefined' || passedinvalue === undefined;

            donotupdate = passedinvalue?.length < 5;
            if (action.payload === null) donotupdate = true;
            if (donotupdate) {
                return {
                    state,
                };
            } else {
                const classvalue =
                    passedinvalue !== 'instructor' ? 'appointments' : 'classes';
                const classSingleValue =
                    passedinvalue !== 'instructor' ? 'appointment' : 'class';
                const instructorPref =
                    passedinvalue !== 'instructor' ? 'a' : 'an';

                return {
                    ...state,
                    studioConfig: {
                        ...state.studioConfig,
                        instructorAlt: passedinvalue,
                        instructorAltName: passedinvalue,
                        instructorPref: instructorPref,
                        classesAltName: classvalue,
                        classAltName: classSingleValue,
                    },
                };
            }
        },
        setInstructorAltNameItalian(state, action) {
            if (
                action.payload === 'undefined' ||
                action.payload === undefined
            ) {
                console.log(
                    'instructor ITALIAL alt name is undefined - need to reject'
                );
                return {
                    state,
                };
            }
            const passedinvalue = lowercaseString(action.payload);
            let donotupdate =
                passedinvalue === 'undefined' || passedinvalue === undefined;

            donotupdate = passedinvalue?.length < 5;
            if (action.payload === null) donotupdate = true;
            if (donotupdate) {
                return {
                    state,
                };
            } else {
                const instructorPref = 'un';

                return {
                    ...state,
                    studioConfig: {
                        ...state.studioConfig,
                        instructorAlt: 'barbiere',
                        instructorAltName: 'barbiere',
                        instructorPref: instructorPref,
                        classesAltName: 'appuntamenti',
                        classAltName: 'appuntamento',
                    },
                };
            }
        },
        setFlashCreditsStore(state, action) {
            state.settings.flashCredits = action.payload;
        },
        setStudioCancelTime(state, action) {
            state.studioConfig.cancelTime = action.payload;
        },
        setStudioTimeZone(state, action) {
            state.studioConfig.timeZone = action.payload;
        },
        setStudioColorRedux(state, action) {
            state.studioConfig.color = action.payload;
        },
        setTimeZone(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    timeZone: action.payload,
                },
            };
        },
    },
});

// Reducer
export default dibsstudio.reducer;
export const {
    addStudioData,
    hasError,
    setSubscriptionInfoFull,
    setFirstClassFixedPrice,
    setShowApptsOnWidget,
    setShowApptsOnAdmin,
    setSubscriptionUpdatedDate,
    setTerms,
    setSubscriptionId,
    setClasspass,
    setPaymentContactInfo,
    setBusinessHours,
    setGympass,
    setShowChatbot,
    setStudioTerms,
    updateSubscriptionInfo,
    setAdminStripeId,
    setTwilioCustomNumUS,
    setInstructorAltName,
    setClassesAltName,
    setDynamicPricing,
    setFlashCreditsStore,
    setGlobalPrices,
    setLocationIds,
    setLocationIdShowing,
    setGeneralLocationData,
    setCustomerServiceEmail,
    setCustomerServicePhone,
    setHashadSubscriptionBefore,
    setCustomEmailToSendFrom,
    setStudioConfigData,
    setStudioImageUrls,
    setOpenTime,
    setCloseTime,
    setStudioColorRedux,
    setAdminAllStripeIds,
    setEntryPage,
    setNumDaysToShowCalendar,
    setRafAwardRedux,
    setStudioCancelTime,
    setTaxesRedux,
    setStudioProfileAccountInfo,
    setPaymentInfo,
    setPaymentCardId,
    cancelSubscriptionRedux,
    setSubscriptionInfo,
    setAdminAccountStripeId,
    setCountry,
    setLocationInfo,
    setCurrencyCode,
    setStudioTimeZone,
    resetDibsStudioData,
    setDibsStudioId,
    setStudioName,
    setShowPrices,
    setInstructorAltNameItalian,
    setApptExceptions,
    setColor,
    setTimeZone,
    setWdth,
} = dibsstudio.actions;

// ----------------------------------------------------------------------
