import { createSlice } from '@reduxjs/toolkit';

const getMaxDateComparison = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);
    return date;
};
const getStartToday = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
};
const getStartOffset = (numdays) => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + numdays);
    return date;
};
const getMaxComparisonOffset = (numdays) => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + (numdays + 1));
    return date;
};

const initialState = {
    showingSchedule: true,
    dateSelected: getStartToday(),
    maxDateForComparison: getMaxDateComparison(),
    movedScheduleOut: 0,
    pageSelected: 'schedule',
    needsRefresh: true,
    openItem: ['dashboard'],
    drawerOpen: false,
    menulocation: 'showClients',
    showChatBot: false,
    showClientSearchTop: false,
    eventIdBeingEdited: null,
    dataForEventSelected: null,
    isReturningFromClientProfile: false,
    lastSelectedEvent: null,
};

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setShowingSchedule(state, action) {
            return {
                ...state,
                showingSchedule: action.payload,
            };
        },
        activeItem(state, action) {
            state.openItem = action.payload;
        },
        setActiveItem(state, action) {
            // is a dupe of above but felt that new naming is more clear
            state.openItem = action.payload;
        },
        resetActiveEventBeingEdited(state) {
            state.eventIdBeingEdited = null;
            state.dataForEventSelected = null;
            state.lastSelectedEvent = null;
            state.isReturningFromClientProfile = false;
        },
        setEventIdBeingEdited(state, action) {
            state.eventIdBeingEdited = action.payload;
        },
        setDataForEventSelected(state, action) {
            state.dataForEventSelected = action.payload;
        },
        setLastSelectedEvent(state, action) {
            state.lastSelectedEvent = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },
        setIsReturningFromClientProfile(state, action) {
            state.isReturningFromClientProfile = action.payload;
        },
        setShowClientSearchTop(state, action) {
            state.showClientSearchTop = action.payload;
        },

        setClientProfileMenu(state, action) {
            state.clientprofilemenu = action.payload;
        },
        setMenuLocation(state, action) {
            return {
                ...state,
                menulocation: action.payload,
            };
        },
        setDateSelected(state, action) {
            return {
                ...state,
                dateSelected: action.payload,
            };
        },
        setNeedsRefresh(state, action) {
            return {
                ...state,
                needsRefresh: action.payload,
            };
        },
        setMaxDateForComparison(state, action) {
            return {
                ...state,
                maxDateForComparison: action.payload,
            };
        },
        setPageSelected(state, action) {
            return {
                ...state,
                pageSelected: action.payload,
            };
        },
        setMovedScheduleOut(state, action) {
            console.log(`action.payload: ${action.payload}`);
            console.log(`state.movedScheduleOut: ${state.movedScheduleOut}`);
            const moveScheduleNum = state.movedScheduleOut + action.payload;
            console.log(`moveScheduleNum: ${moveScheduleNum}`);
            return {
                ...state,
                movedScheduleOut: moveScheduleNum,
                dateSelected: getStartOffset(moveScheduleNum),
                maxDateForComparison: getMaxComparisonOffset(moveScheduleNum),
            };
        },
        setMovedScheduleBack(state, action) {
            console.log(`action.payload: ${action.payload}`);
            console.log(`state.movedScheduleOut: ${state.movedScheduleOut}`);
            const moveScheduleNum = state.movedScheduleOut - action.payload;
            console.log(`moveScheduleNum: ${moveScheduleNum}`);
            return {
                ...state,
                movedScheduleOut: moveScheduleNum,
                dateSelected: getStartOffset(moveScheduleNum),
                maxDateForComparison: getMaxComparisonOffset(moveScheduleNum),
            };
        },
    },
});

export default menu.reducer;
export const {
    setShowingSchedule,
    setEventIdBeingEdited,
    setLastSelectedEvent,
    setDateSelected,
    resetActiveEventBeingEdited,
    setDataForEventSelected,
    setShowClientSearchTop,
    setMaxDateForComparison,
    setMovedScheduleOut,
    setMovedScheduleBack,
    setPageSelected,
    setNeedsRefresh,
    setMenuLocation,
    activeItem,
    setActiveItem,
    setIsReturningFromClientProfile,
    openDrawer,
    setClientProfileMenu,
} = menu.actions;
