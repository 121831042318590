import { createSelector } from 'reselect';

import { DEFAULT_CANCEL_TIME } from '../../constants/UserConstants';

/**
 * @param {Object} state in store
 * @returns {Object} user state
 */
export function getUser(state) {
    return state.user || {};
}

/**
 * @param {Object} state in store
 * @returns {string} user firstname
 */
export function getUserFirstName(state) {
    return getUser(state).firstName || '';
}

/**
 * @param {Object} state in store
 * @returns {string} user firstname
 */
export function getUserLastName(state) {
    return getUser(state).lastName || '';
}

/**
 * @param {Object} state in store
 * @returns {string} user firstname
 */
export function getUserEmail(state) {
    return getUser(state).email || '';
}

/**
 * @param {Object} state in store
 * @returns {Object} user's studio
 */
export function getUserStudio(state) {
    return getUser(state).studio || {};
}

/**
 * @param {Object} state in store
 * @returns {Object} user's studio
 */
export function getUserStudioBillingContact(state) {
    return getUserStudio(state).billing_contact || '';
}

/**
 * @param {Object} state in store
 * @returns {Object} user's studio
 */
export function getUserStudioBillingEmail(state) {
    return getUserStudio(state).billing_email || '';
}

/**
 * @param {Object} state in store
 * @returns {boolean} if studio offers packages on Dibs
 */
export function getUserStudioHasPackages(state) {
    return Boolean(getUserStudio(state).hasPackages);
}

/**
 * @param {Object} state in store
 * @returns {string} user studio name
 */
export function getUserStudioName(state) {
    return getUserStudio(state).name || '';
}

/**
 * @param {Object} state in store
 * @returns {string} user studio name
 */
export function getUserStudioSubscriptionRate(state) {
    return getUserStudio(state).subscription_fee || 0;
}

/**
 * @param {Object} state in store
 * @returns {string} user studio name
 */
export function getUserStudioTotalMonthlyCharge(state) {
    return getUserStudio(state).total_monthly_charge || 0;
}

/**
 * @param {Object} state in store
 * @returns {string} user studio name
 */
export function getUserStudioDateOfCharge(state) {
    return getUserStudio(state).date_of_charge || 0;
}

/**
 * @param {Object} state in store
 * @returns {string} user studio name
 */
export function getUserStudioWidgetFee(state) {
    return getUserStudio(state).widget_fee_rate || 0;
}

/**
 * @param {Object} state in store
 * @returns {string} user studio currency
 */
export function getUserStudioCurrency(state) {
    return getUserStudio(state).currency || 'USD';
}

/**
 * @param {Object} state in store
 * @returns {string} user studio main timezone
 */
export function getUserStudioMainTZ(state) {
    return getUserStudio(state).mainTZ || 'America/New_York';
}

/**
 * @param {Object} state in store
 * @returns {string} user studio account id
 */
export function getUserStudioStripeAccountId(state) {
    return getUserStudio(state).stripe_account_id || '';
}

/**
 * @param {Object} state in store
 * @returns {string} user studio live
 */
export function getUserStudioLive(state) {
    return getUserStudio(state).live || false;
}

/**
 * @param {Object} state in store
 * @returns {string} user studio live
 */
export function getUserStudioClickedLink(state) {
    return getUserStudio(state).clicked_mb_link || false;
}

/**
 * @param {Object} state in store
 * @returns {Array<Object>} user studio's locations
 */
export function getUserStudioLocations(state) {
    return getUserStudio(state).locations || [];
}

/**
 * @param {Object} state in store
 * @returns {string} user studio country
 */
export function getUserStudioCountry(state) {
    return getUserStudio(state).country || 'US';
}

/**
 * @param {Object} state in store
 * @returns {number} user studio's id in our db
 */
export function getUserStudioDibsId(state) {
    return getUserStudio(state).id || null;
}

/**
 * @param {Object} state in store
 * @returns {Array<Object>} user studio source
 */
export function getUserStudioSource(state) {
    return getUserStudio(state).source || '';
}

/**
 * @param {Object} state in store
 * @returns {number} cancel time for early cancel (hours)
 */
export function getUserStudioCancelTime(state) {
    return getUserStudio(state).cancel_time || DEFAULT_CANCEL_TIME;
}

/**
 * @param {Object} state in store
 * @returns {Object} user studio Dibs config object
 */
export function getUserStudioDibsConfig(state) {
    return getUserStudio(state).dibs_config || {};
}

/**
 * @param {Object} state in store
 * @returns {boolean} if the studio has a chatbot live
 */
export function getUserStudioHasChatbot(state) {
    return Boolean(getUserStudioDibsConfig(state).show_chatbot);
}

/**
 * @param {Object} state in store
 * @returns {boolean} if the studio has a chatbot live
 */
export function getUserStudioHasGiftCards(state) {
    return Boolean(getUserStudioDibsConfig(state).display_giftcards);
}

/**
 * @param {Object} state in store
 * @returns {boolean} if the studio requires users to sign a waiver
 */
export function getUserStudioRequiresWaiver(state) {
    return Boolean(getUserStudio(state).requiresWaiverSigned);
}

/**
 * @param {Object} state in store
 * @returns {number} of days to display in the front desk schedule
 */
export function getUserStudioIntervalEnd(state) {
    return Number(getUserStudioDibsConfig(state).intervalEnd) || 14;
}

/**
 * @param {Object} state in store
 * @returns {number} fixed first class price
 */
export function getUserStudioFirstClassFixedPrice(state) {
    return getUserStudioDibsConfig(state).first_class_fixed_price;
}
/**
 * @param {Object} state in store
 * @returns {number} fixed first class price
 */
export function getUserStudioFlashCredits(state) {
    return getUserStudioDibsConfig(state).send_flash_credits;
}

export const getIsUserStudioTaxRateApplicable = createSelector(
    getUserStudioLocations,
    (locations) =>
        locations.some((location) => location.tax_rate && location.tax_rate > 0)
);

export const getUserStudioCreditTiers = createSelector(
    getUserStudio,
    (studio) =>
        studio.defaultCreditTiers
            ? studio.defaultCreditTiers.map((creditTier) => ({
                  ...creditTier,
                  isSpecial: false,
              }))
            : []
);

export const getUserStudioDateFormat = createSelector(
    getUserStudioCountry,
    (country) => ({ US: 'M/D/YY', UK: 'D/M/YY' })[country]
);

export const getUserStudioShortDateFormat = createSelector(
    getUserStudioCountry,
    (country) => ({ US: 'M/D/Y', UK: 'D/M/Y' })[country]
);

export const getUserStudioShorterDateFormat = createSelector(
    getUserStudioCountry,
    (country) => ({ US: 'M/D', UK: 'D/M' })[country]
);

export const getUserStudioId = createSelector(
    getUserStudio,
    (studio) => studio.studioid
);

export const getUserStudioFrontDesk = createSelector(
    getUserStudio,
    (studio) => studio.front_desk
);

export const getUserStudioHasPayouts = createSelector(
    getUserStudio,
    (studio) => studio.hasPayouts
);

export const getUserIsAdmin = createSelector(getUser, (user) => user.admin);

export const getUserIsInstructorOnly = createSelector(
    getUser,
    (user) => user.instructor_only
);

export const getUserStudioHasMultipleLocations = createSelector(
    getUserStudioLocations,
    (locations) => locations?.length > 1
);

export const getStudioCustomTimeFormat = createSelector(
    getUserStudioDibsConfig,
    (dibsConfig) => dibsConfig.customTimeFormat
);

export const getPrimaryStudioLocation = createSelector(
    [getUserStudioLocations, getUserStudio],
    (locations, studio) =>
        locations.find(
            (l) => l.source_location_id === studio.primary_location_id
        )
);

export const getPrimaryLocationTaxes = createSelector(
    getPrimaryStudioLocation,
    (location) => location.tax_rate
);

export const getPrimaryLocationRetailTaxes = createSelector(
    getPrimaryStudioLocation,
    (location) => location?.retail_tax_rate
);

export const getUserStudioHasSpotBooking = createSelector(
    getUserStudioDibsConfig,
    (dibsConfig) => Boolean(dibsConfig.use_spot_booking)
);
