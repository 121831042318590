import { dispatch } from 'store';

// NOTE - this will replace everything that is there

import { setSubscriptionInfoFull } from 'store/slices/dibsstudio';

export default async function setFullSubscriptionInfo(
    subscriptionInfo,
    status
) {
    switch (status) {
        case 'active':
            console.log('Active subcription');
            const datatosend = {
                id: '',
                isActive: false,
                current_period_start: null,
                current_period_end: null,
                default_payment_method: null,
                default_source: null,
                ended_at: null,
                planid: '',
                planAmount: 0,
                discount: 0,
                nextChargeDate: null,
                trialend: null,
                onFreeTrial: true,
                numTrialDays: 0,
                canceledOn: null,
                originallyStarted: null,
                canceledBy: null,
                willEndAtEndOfPeriod: false,
                wasCanceled: false,
            };
            console.log(
                'TODO - Put this info in for the case where the subscription is actually active.'
            );
            dispatch(setSubscriptionInfoFull(datatosend));

            break;

        case 'canceled':
            console.log('Had one, but it was canceled');
            const datatosendcanceled = {
                id: '',
                isActive: false,
                current_period_start: null,
                current_period_end: null,
                default_payment_method: null,
                default_source: null,
                ended_at: null,
                planid: '',
                planAmount: 0,
                discount: 0,
                nextChargeDate: null,
                trialend: null,
                onFreeTrial: true,
                numTrialDays: 0,
                canceledOn: null,
                originallyStarted: null,
                canceledBy: null,
                willEndAtEndOfPeriod: false,
                wasCanceled: false,
            };
            console.log(
                'TODO - Put this info in for the case where the subscription is canceled.'
            );
            dispatch(setSubscriptionInfoFull(datatosendcanceled));
            break;

        case 'never':
            console.log('Never had one');
            const datatosendnever = {
                id: '',
                isActive: false,
                current_period_start: null,
                current_period_end: null,
                default_payment_method: null,
                default_source: null,
                ended_at: null,
                planid: '',
                planAmount: 0,
                discount: 0,
                nextChargeDate: null,
                trialend: null,
                onFreeTrial: false,
                numTrialDays: 0,
                canceledOn: null,
                originallyStarted: null,
                canceledBy: null,
                willEndAtEndOfPeriod: false,
                wasCanceled: false,
            };
            console.log(
                'TODO - Put this info in for the case where the subscription is actually active.'
            );
            dispatch(setSubscriptionInfoFull(datatosendnever));
            break;

        default:
            console.log(
                'SHOULD NEVER SEE THIS: Do not have subscription status info - need to handle that to make sure that does not happen'
            );
    }
    console.log('\n\n\n\nSUBSCRIPTION INFO IS', subscriptionInfo);
    console.log('\n\n\n\nSTATUS IS', status);
    // dispatch(setSubscriptionInfoFull(subscriptionInfo));
    return 1;
}
