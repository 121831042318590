import { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector } from 'store';

export default function SimpleBackdrop() {
    const [open, setOpen] = useState(true);
    const [bgColor, setBgColor] = useState('rgba(0, 0, 0, 0.8)');
    const { pageIsLoading, loaderShouldBeDarker } = useSelector(
        (state) => state.loadingStatus
    );
    useEffect(() => {
        if (pageIsLoading) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [pageIsLoading]);
    useEffect(() => {
        if (loaderShouldBeDarker) {
            setBgColor('rgba(147, 140, 140, 0.6)');
        } else {
            setBgColor('rgba(0, 0, 0, 0.5)');
        }
    }, [loaderShouldBeDarker]);

    return (
        <div>
            <Backdrop
                transitionDuration={320}
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: bgColor,
                    backdropFilter: 'blur(5px)',
                }}
                open={open}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
