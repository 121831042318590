import { useState, useEffect } from 'react';
// material-ui

import { Button, Grid, Stack, TextField } from '@mui/material';

import { NumericFormat } from 'react-number-format';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

import createNewDibsUser from 'actions/users/createNewDibsUser.js';
import createNewShopifyUser from 'actions/shopify/createNewShopifyUser';
import recordShopifyStatus from 'actions/shopify/recordShopifyStatus';
import { useSelector } from 'store';

import { capitalizeFirstLetter } from 'helpers/general';
import setAlert from 'actions/status/setAlert';
import translate from 'helpers/translate';
import sendOpsEmail from 'actions/comm-logs/sendStandAloneOpsEmailNew';

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),
    firstName: yup.string().required('First name is required'),
});

const myStyle = {
    minWidth: '300px',
    width: '98%',
    boxShadow: 'none',
    padding: '10px',
};

// ==============================|| FORM VALIDATION - NEW CLIENT ACCOUNT  ||============================== //
let runShopify = true;
const testingShopifyIntegration = false;
const environ = process.env.NODE_ENV;
if (environ === 'development') {
    runShopify = false;
}
if (testingShopifyIntegration) {
    runShopify = true;
}

const NewClientAccountForm = () => {
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );
    const [processShopify, setProcessShopify] = useState(false);
    useEffect(() => {
        if (dibsStudioId === '226' || dibsStudioId === '260') {
            if (runShopify) {
                setProcessShopify(true);
            } else {
                setProcessShopify(false);
            }
        }
    }, [dibsStudioId]);
    const sendLogEmail = (userid, subjectPrefix, whathappened) => {
        sendOpsEmail({
            userid,
            dibsId: dibsStudioId,
            eventid: null,
            typeofevent: 'creating new client on studio admin',
            subject: `${subjectPrefix} @ ${dibsStudioId}`,
            relevantId: null,
            idStringName: 'N/A',
            action: 'create-user',
            studioEmployeeId,
            extraInfo: `${whathappened}`,
            fromWhere: `Created new account on studio admin`,
        });
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            phone2: '',
        },
        validationSchema,
        onSubmit: async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
            let useridcreated;
            const valuestopassforerrors = values;
            try {
                const thisemail = values.email;
                const newfirstname = capitalizeFirstLetter(values.firstName);
                const valuestosend = {
                    firstname: newfirstname,
                    lastname: capitalizeFirstLetter(values.lastName),
                    birthday: null,
                    email: thisemail.toLowerCase(),
                    phone: values.phone2,
                    studioEmployeeId,
                    dibsId: dibsStudioId,
                    countryAbbr: 'US',
                };

                const newuser = await createNewDibsUser(valuestosend);
                console.log(
                    `\n\n\nnewuser returned is: ${JSON.stringify(newuser, null, 4)}`
                );
                sendOpsEmail({
                    userid: newuser.userid,
                    dibsId: dibsStudioId,
                    eventid: 'N/A',
                    typeofevent: 'creating new client on studio admin',
                    subject: `Admin Created New Client Account @ ${dibsStudioId}`,
                    relevantId: newuser.userid,
                    idStringName: 'USERID',
                    action: 'created',
                    studioEmployeeId,
                    extraInfo: `Created new client account for ${newfirstname}.\n\n${JSON.stringify(
                        valuestosend,
                        null,
                        4
                    )}`,
                    fromWhere: `Created new account on studio admin - on front desk page`,
                });

                const { userid, code, error, added_to_shopify } = newuser;
                useridcreated = userid;
                console.log(`code returned is: ${code}`);

                if (code === 18) {
                    setAlert({
                        msg: `A user with this email address already exists. Please use a different email address or look up the client's account above.`,
                        warning: true,
                        seconds: 20,
                    });

                    return;
                } else if (code === 2) {
                    setAlert({
                        msg: `The account was created for ${newfirstname}. Please note: The phone number that you provided is already associated with another account (${newuser.otheruserfirstname} ${newuser.otheruserlastname}), so it could not be added to this account. If you would like to add it to this account, please remove it from ${newuser.firstname}'s account first. If you need help, please contact support.`,
                        warning: true,
                        seconds: 30,
                    });
                    resetForm();
                    if (!processShopify) return;
                } else if (code === 1 || code === 11) {
                    setAlert({
                        msg: `All set! The account was created for ${newfirstname}.`,
                        success: true,
                        seconds: 7,
                    });
                    resetForm();
                    if (!processShopify) return;
                } else if (code === 0) {
                    setAlert({
                        msg: `There was an error creating the new account for ${newfirstname}. The error is ${error}. Please refresh your screen and then try again. If the problem persists, please contact support.`,
                        error: true,
                        seconds: 30,
                    });
                    resetForm();
                    return;
                }

                if (added_to_shopify) {
                    console.log(
                        'this user has already been added to shopify - no need to continue'
                    );
                    console.log(
                        'This is ONLY done for pisterzi. if we add more shopify integrations for other studios, this will need to be changed'
                    );
                    console.log(
                        'will need to move shopify fields to dibs_user_studios'
                    );
                    // user has already been added to shopify. no need to continue
                    // setAlert({
                    //     msg: `Success! A new client account has been created for ${newfirstname}.`,
                    //     success: true,
                    //     seconds: 7,
                    // });
                }
                // otherwise, the user still needs to be added to shopify
                if (!runShopify || added_to_shopify) {
                    resetForm();
                    return;
                }
                if (dibsStudioId === '226' || dibsStudioId === '260') {
                    console.log(
                        '\n\n\nABOUT to run the shopify part of the integration'
                    );
                    const newpwd = `Dibs!1234${Math.floor(Math.random() * 100000)}`;
                    const thisdata = {
                        dibsStudioId,
                        email: thisemail,
                        phone: values.phone2,
                        newpwd,
                        countryOrigin: 'US',
                    };
                    if (dibsStudioId === '260') {
                        thisdata.countryOrigin = 'IT'; // Italy
                    }
                    const newShopifyUser = await createNewShopifyUser(
                        thisdata,
                        userid
                    );

                    const sendShopifyLogEmail = (
                        subjectPrefix,
                        whathappened
                    ) => {
                        console.log(
                            '\n\n\n\nsending the shopify log email here '
                        );
                        sendOpsEmail({
                            userid,
                            dibsId: dibsStudioId,
                            eventid: null,
                            typeofevent:
                                'creating new client account on shopify',
                            subject: `SHOPIFY LOG EMAIL --- ${subjectPrefix} @ ${dibsStudioId}`,
                            relevantId: null,
                            idStringName: 'N/A',
                            action: 'shopify',
                            studioEmployeeId,
                            extraInfo: `Something occured with the integration with shopify when creating a new client account for ${newfirstname}.\n\n${whathappened}`,
                            fromWhere: `Created new account on studio admin - had an alert for shopify`,
                        });
                    };
                    console.log(
                        'next - we will record the shopify status to confirm that we did or did not update it'
                    );
                    if (newShopifyUser) {
                        const { createdNewCustomer } = newShopifyUser;
                        if (createdNewCustomer) {
                            await recordShopifyStatus(newShopifyUser, userid);
                            sendShopifyLogEmail(
                                'Created new shopify user',
                                `${newShopifyUser} was created.`
                            );
                        }
                    } else {
                        // taking off the alerts here so that the user does not get a double alert. I will send an ops email to monitor when errors are happening with the shopify integration
                        sendShopifyLogEmail(
                            'Error creating shopify user',
                            `error while creatig shopify user after creating new account. You have the userid here. Check the email logs.`
                        );
                    }
                }
                // setAlert({
                //     msg: `Success! A new client account has been created for ${newfirstname}.`,
                //     success: true,
                //     seconds: 7,
                // });
                resetForm();
            } catch (error) {
                setSubmitting(false);
                setErrors({ submit: error.message });
                setStatus({ success: false });
                setAlert({
                    msg: `There was an error creating a client account. Please refresh your screen and then try again. If the problem persists, please contact support.`,
                    error: true,
                    seconds: 7,
                });
                const subjectPrefix =
                    'Error caught creating new client account';
                const whathapps = `Values that were passed in via the form were: ${JSON.stringify(valuestopassforerrors, null, 4)}\n\nThe error was: ${error}`;
                sendLogEmail(useridcreated, subjectPrefix, whathapps);
            }
        },
    });
    const firstnameT = translate({
        id: 'firstname',
        msg: 'First Name',
    });
    const lastnamT = translate({
        id: 'lastname',
        msg: 'Last Name',
    });
    const phoneT = translate({
        id: 'phone',
        msg: 'Phone #',
    });
    const verify = translate({
        id: 'verifysubmit',
        msg: 'Verify & Submit',
    });

    return (
        <form
            style={myStyle}
            className="form-profile"
            onSubmit={formik.handleSubmit}
        >
            <Grid item xs={12} md={10} lg={7} border={0}>
                <Grid container spacing={3}>
                    <Grid item xs={11} md={5} id="first-name-textfield">
                        <TextField
                            fullWidth
                            id="firstName"
                            size="small"
                            name="firstName"
                            label={firstnameT}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.firstName &&
                                Boolean(formik.errors.firstName)
                            }
                            helperText={
                                formik.touched.firstName &&
                                formik.errors.firstName
                            }
                        />
                    </Grid>
                    <Grid item xs={11} md={5} id="lastName-textfield">
                        <TextField
                            fullWidth
                            id="lastName"
                            size="small"
                            name="lastName"
                            label={lastnamT}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={11}
                        md={7}
                        sx={{ marginTop: '24px' }}
                        id="email-textfield"
                    >
                        <TextField
                            fullWidth
                            id="email"
                            size="small"
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={8}
                        md={4}
                        sx={{ marginTop: '24px' }}
                        id="grid-holding-numeric-format-phone"
                    >
                        <Grid
                            item
                            sx={{ marginTop: '3px' }}
                            id="numberic-format-phone"
                        >
                            <NumericFormat
                                id="phone2"
                                size="small"
                                label={phoneT}
                                format="+1 (###) ###-####"
                                mask="_"
                                fullWidth
                                value={formik.values.phone2}
                                customInput={TextField}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.phone2 &&
                                    Boolean(formik.errors.phone2)
                                }
                                helperText={
                                    formik.touched.phone2 &&
                                    formik.errors.phone2
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} id="button-holder-for-submit-new-client">
                        <Stack direction="row" justifyContent="flex-start">
                            <AnimateButton>
                                <Button
                                    id="verify-submit-btn-new-client"
                                    variant="contained"
                                    type="submit"
                                    disableElevation
                                >
                                    {verify}
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default NewClientAccountForm;
