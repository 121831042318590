import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Grid,
    Typography,
    List,
    ListItemIcon,
    ListItemButton,
    ListItem,
    ListItemText,
    Divider,
    Stack,
    Fade,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import CheckCircleIcon from 'assets/icons/checkmarkIcon';

import ConfirmClientRemoval from 'components/schedule-sections/confirmClientRemoval';

import { capitalizeFirstLetter } from 'helpers/general';
import compareApptTimeToNow from 'helpers/dayjs/compareNowToApptTime.js';
import updateAttendanceClient from 'actions/schedule/updateAttendance';
import { formatPhoneNumberDomestic } from 'helpers/phone-numbers';
import {
    setPageSelected,
    setActiveItem,
    setEventIdBeingEdited,
} from 'store/slices/menu';
import {
    setPageIsLoading,
    setLoaderShouldBeDarker,
    setShowOptionToReturnToSchedule,
} from 'store/slices/loadingStatus';

import { useSelector, dispatch } from 'store';

const BookedClients = ({
    attendees,
    setHideSaveButtons,
    thiseventid,
    dibsId,
    startTime,
    setUpdateEvents,
    handleClientSelection,
    eventType,
    setClientSelected,
    handleChangeWasMade,
    refreshAttendees,
}) => {
    const navigate = useNavigate();
    const [isChangingClient, setIsChangingClient] = useState(false);
    const [currentClient, setCurrentClient] = useState(null);
    const [showCheckinText, setShowCheckinText] = useState(false);
    const { timeZone } = useSelector((state) => state.dibsstudio.config);
    const [attendeesToShow, setAttendeesToShow] = useState(attendees);
    const [showConfirmationText, setShowConfirmationText] = useState(false);
    const [statusRecentlyChanged, setStatusRecentlyChanged] = useState([]);
    const clickTimeout = useRef(null);
    const clickCount = useRef(0);
    const delay = 250; // adjust as needed

    const handleClicksTest = ({ attendeeId, userId, checkedin }) => {
        console.log('\n\nattendee id is', attendeeId);
        console.log('\n\nuser id is', userId);
        console.log('\nchecked in is', checkedin);
        clickCount.current += 1;

        // Clear any existing timer
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
        }
        const processCheckin = async (
            attendeeId,
            userId,
            shouldBeCheckedIn
        ) => {
            const returnvalue = await updateAttendanceClient(
                attendeeId,
                shouldBeCheckedIn,
                userId
            );
            if (returnvalue?.success) {
                refreshAttendees(thiseventid);
                setShowConfirmationText(true);
                setTimeout(() => {
                    setShowConfirmationText(false);
                    setStatusRecentlyChanged([]);
                }, 2500);
            }
        };

        // Start a new timer to check the click count after a delay
        clickTimeout.current = setTimeout(() => {
            console.log('\n\n\nClickCount CURRENT is', clickCount.current);
            if (clickCount.current === 1) {
                console.log('\n\n\nHERE ---> Single click action');
                if (!checkedin) {
                    console.log(
                        '\n\n\nUSER IS NOT CHECKED IN --- SO GO THROUGH CHECK IN PROCESS'
                    );
                    const newArray = [...statusRecentlyChanged];
                    newArray.push(userId);
                    setStatusRecentlyChanged(newArray);
                    processCheckin(attendeeId, userId, true);
                } else {
                    console.log(
                        '\n\n\nUSER IS CHECKED IN --- SO GO THROUGH CHECK OUT PROCESS'
                    );
                    const newArray = [...statusRecentlyChanged];
                    newArray.push(userId);
                    setStatusRecentlyChanged(newArray);
                    processCheckin(attendeeId, userId, false);
                }
            } else if (clickCount.current === 2) {
                console.log('\n\n\nHERE IS ---> Double click action');
                const url = `/frontdesk/${dibsId}/clients/${userId}`;
                dispatch(setPageSelected('client-profile'));
                dispatch(setActiveItem(['frontdesk']));
                dispatch(setEventIdBeingEdited(thiseventid));
                dispatch(setPageIsLoading(true));
                dispatch(setLoaderShouldBeDarker(true));
                dispatch(setShowOptionToReturnToSchedule(true));
                navigate(url);
            }
            // Reset the click count
            clickCount.current = 0;
        }, delay);
    };

    useEffect(() => {
        const timeDifference = compareApptTimeToNow(
            startTime,
            timeZone,
            'hours'
        );
        if (timeDifference < 10) setShowCheckinText(true);
        else setShowCheckinText(false);
    }, [startTime, timeZone]);
    useEffect(() => {
        if (attendees) {
            setAttendeesToShow(attendees);
        } else {
            setAttendeesToShow([]);
        }
    }, [attendees]);

    if (!attendeesToShow) return null;
    const removeClient = (client) => {
        console.log('\n\n\nRemove client was clicked', client);
        setCurrentClient(client);
        setHideSaveButtons(true);
        setIsChangingClient(true);
        handleClientSelection(client, 'fromBookedClients');
    };
    const cancelRemovingClient = () => {
        setHideSaveButtons(false);
        setIsChangingClient(false);
    };

    if (isChangingClient)
        return (
            <ConfirmClientRemoval
                currentClient={currentClient}
                cancelRemovingClient={cancelRemovingClient}
                thiseventid={thiseventid}
                handleChangeWasMade={handleChangeWasMade}
                dibsId={dibsId}
                startTime={startTime}
                setUpdateEvents={setUpdateEvents}
                eventType={eventType}
                setClientSelected={setClientSelected}
            />
        );

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{ p: 0, m: 0 }}
                id="item-holding-list-booked-clients"
            >
                <List>
                    <Grid
                        key="booked-clients-list-container"
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {attendeesToShow.map((attendee, i) => {
                            const {
                                firstname,
                                userid,
                                lastname,
                                email,
                                mobilephone,
                                checkedin,
                            } = attendee;

                            let phonetouse = mobilephone;
                            if (!phonetouse) phonetouse = 'No phone on file';
                            else
                                phonetouse =
                                    formatPhoneNumberDomestic(mobilephone);
                            return (
                                <React.Fragment
                                    key={`book-client-fragment-${userid}-${i}`}
                                >
                                    <Grid
                                        item
                                        xs={11}
                                        border={0}
                                        sx={{ m: 0 }}
                                        id="item-holding-list-item-booked-client"
                                    >
                                        <ListItem
                                            key={`listitem-${userid}`}
                                            id={`listitem-userid-${userid}`}
                                            sx={{ pl: 0 }}
                                        >
                                            <ListItemButton
                                                sx={{ p: 0 }}
                                                key={`listbutton-${userid}`}
                                                id={`listbutton-userid-${userid}`}
                                                onClick={() => {
                                                    handleClicksTest({
                                                        attendeeId: attendee.id,
                                                        userId: userid,
                                                        checkedin,
                                                    });
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{ minWidth: '22px' }}
                                                    key={`listitemicon-${userid}`}
                                                    id={`listitemicon-userid-${userid}`}
                                                >
                                                    <CircleIcon
                                                        color="secondary"
                                                        sx={{ fontSize: 10 }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    key={`listitemtext-${userid}`}
                                                    id={`listitemtext-userid-${userid}`}
                                                    primary={
                                                        <>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                            >
                                                                {showConfirmationText &&
                                                                    statusRecentlyChanged.includes(
                                                                        userid
                                                                    ) && (
                                                                        <Fade
                                                                            in
                                                                            timeout={
                                                                                450
                                                                            }
                                                                            easing={
                                                                                'ease-in-out'
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                id={`checkedintextfor-${userid}`}
                                                                                sx={{
                                                                                    mb: 0.75,
                                                                                }}
                                                                            >
                                                                                <Typography variant="successText">
                                                                                    {capitalizeFirstLetter(
                                                                                        firstname
                                                                                    )}{' '}
                                                                                    is{' '}
                                                                                    {checkedin
                                                                                        ? 'checked'
                                                                                        : 'no longer checked'}{' '}
                                                                                    in
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Fade>
                                                                    )}

                                                                <Grid
                                                                    item
                                                                    id={`bookedclientsname-${userid}`}
                                                                    xs={12}
                                                                    border={0}
                                                                    // onClick={async () => {
                                                                    //     if (
                                                                    //         checkedin
                                                                    //     ) {
                                                                    //         const returnvalue =
                                                                    //             await updateAttendanceClient(
                                                                    //                 attendee.id,
                                                                    //                 false,
                                                                    //                 userid
                                                                    //             );

                                                                    //         if (
                                                                    //             returnvalue?.success
                                                                    //         ) {
                                                                    //             const newArray =
                                                                    //                 [
                                                                    //                     ...statusRecentlyChanged,
                                                                    //                 ];
                                                                    //             newArray.push(
                                                                    //                 userid
                                                                    //             );
                                                                    //             setStatusRecentlyChanged(
                                                                    //                 newArray
                                                                    //             );
                                                                    //             refreshAttendees(
                                                                    //                 thiseventid
                                                                    //             );
                                                                    //             setShowConfirmationText(
                                                                    //                 true
                                                                    //             );
                                                                    //             setTimeout(
                                                                    //                 () => {
                                                                    //                     setShowConfirmationText(
                                                                    //                         false
                                                                    //                     );
                                                                    //                     setStatusRecentlyChanged(
                                                                    //                         []
                                                                    //                     );
                                                                    //                 },
                                                                    //                 3500
                                                                    //             );
                                                                    //         }
                                                                    //     }
                                                                    // }}
                                                                >
                                                                    <Stack
                                                                        direction="row"
                                                                        id={`stack-booked-name-${userid}`}
                                                                    >
                                                                        <Typography variant="bookedclientsname">
                                                                            {`${capitalizeFirstLetter(firstname)} ${capitalizeFirstLetter(lastname)}`}
                                                                        </Typography>{' '}
                                                                        {checkedin && (
                                                                            <CheckCircleIcon />
                                                                        )}
                                                                    </Stack>
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    border={0}
                                                                    sx={{
                                                                        mr: 1.5,
                                                                    }}
                                                                    id="bookedclients-email-phone"
                                                                >
                                                                    <Typography variant="bookedclients">
                                                                        {email}
                                                                    </Typography>
                                                                </Grid>
                                                                <Divider
                                                                    orientation="vertical"
                                                                    sx={{
                                                                        borderColor:
                                                                            '#ccc',
                                                                    }}
                                                                    flexItem
                                                                />
                                                                <Grid
                                                                    item
                                                                    id="bookedclients-email-phone-phone"
                                                                    sx={{
                                                                        ml: 1.5,
                                                                    }}
                                                                    border={0}
                                                                >
                                                                    <Typography variant="bookedclients">
                                                                        {
                                                                            phonetouse
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        border={0}
                                        id={`clearicon-${userid}`}
                                        key={`clearicon-${userid}`}
                                    >
                                        <Grid
                                            container
                                            key={`cleariconcontainer-${userid}`}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                id={`clearicon-item-${userid}`}
                                                border={0}
                                                key={`clearicon-item-${userid}`}
                                            >
                                                <ClearIcon
                                                    key={`clearicon-icon-booked-${userid}`}
                                                    id={`clearicon-icon-booked-${userid}`}
                                                    color="secondary"
                                                    onClick={() => {
                                                        console.log(
                                                            '\n\n\nClear icon was clicked'
                                                        );
                                                        removeClient(attendee);
                                                    }}
                                                    sx={{
                                                        fontSize: 20,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {showCheckinText && !checkedin && (
                                        <Grid
                                            item
                                            xs={11.5}
                                            id={`checkin-text-${userid}-${firstname}`}
                                            onClick={async () => {
                                                console.log(
                                                    '\n\nCASE WHERE NOT CHECKED In'
                                                );
                                                const newArray = [
                                                    ...statusRecentlyChanged,
                                                ];
                                                newArray.push(userid);
                                                setStatusRecentlyChanged(
                                                    newArray
                                                );
                                                const returnvalue =
                                                    await updateAttendanceClient(
                                                        attendee.id,
                                                        true,
                                                        userid
                                                    );

                                                if (returnvalue?.success) {
                                                    refreshAttendees(
                                                        thiseventid
                                                    );
                                                    setShowConfirmationText(
                                                        true
                                                    );
                                                    setTimeout(() => {
                                                        setShowConfirmationText(
                                                            false
                                                        );
                                                        setStatusRecentlyChanged(
                                                            []
                                                        );
                                                    }, 2500);
                                                }
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                                mb: 1,
                                            }}
                                        >
                                            <Grid
                                                container
                                                justifyContent="flex-end"
                                            >
                                                <Typography variant="successText">
                                                    Check In{' '}
                                                    {capitalizeFirstLetter(
                                                        firstname
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </List>
            </Grid>
        </Grid>
    );
};

export default BookedClients;
